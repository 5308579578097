<div class="card">
    <div class="card-body">
      <h5>Add Client Data</h5>
      <hr />
      <form [formGroup]="clientForm">
        <div class="form-group mb-2">
          <label for="name" class="mb-2">Client Name</label>
          <input
            id="name"
            formControlName="name"
            class="form-control"
            placeholder="Enter client name"
          />
          <div *ngIf="clientForm.get('name')?.invalid && clientForm.get('name')?.touched">
            <small class="text-danger">Client name is required</small>
          </div>
        </div>
  
        <div class="form-group mb-2">
          <label for="phoneNumber" class="mb-2">Phone Number</label>
          <input
            id="phoneNumber"
            formControlName="phoneNumber"
            class="form-control"
            placeholder="Enter phone number"
          />
          <div *ngIf="clientForm.get('phoneNumber')?.invalid && clientForm.get('phoneNumber')?.touched">
            <small class="text-danger">Phone number is required</small>
          </div>
        </div>
        <div class="form-group mb-2">
          <label for="preferredLanguages" class="mb-2">Preferred Language</label>
          <input
            id="preferredLanguages"
            formControlName="preferredLanguages"
            class="form-control"
            placeholder="Enter Client Preferred Language"
          />
          <div *ngIf="clientForm.get('preferredLanguages')?.invalid && clientForm.get('preferredLanguages')?.touched">
            <small class="text-danger">Preferred Language is required</small>
          </div>
        </div>
  
        <hr />
        <div class="mt-3 d-flex justify-content-end">
          <button
            type="button"
            class="btn brand-btn btn-success"
            (click)="saveClient()"
            [disabled]="clientForm.invalid"
          >
            Save Client
          </button>
          <button
            type="button"
            class="btn brand-btn bg-red1 text-white ms-2"
            (click)="close()"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>
  