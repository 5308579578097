<form [formGroup]="editPurchaseForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="supplier" class="form-label">Supplier</label>
      <input type="text" id="supplier" formControlName="supplier" class="form-control" required>
    </div>
      <div class="mb-3">
        <label for="quantity" class="form-label">Quantity</label>
        <input type="text" id="quantity" formControlName="quantity" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">Status</label>
        <input type="text" id="status" formControlName="status" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="expectedDate" class="form-label">Expected Date</label>
        <input type="date" id="expectedDate" formControlName="expectedDate" class="form-control" required>
      </div>
      <div class="mb-3">
        <label for="rate" class="form-label">Rate</label>
        <input type="text" id="rate" formControlName="rate" class="form-control" required>
      </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save Changes</button>
    <a [routerLink]="['/purchase-detail',purchaseId]" class="btn btn-secondary">Cancel</a>
  </div>
</form>
