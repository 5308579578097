import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';

@Component({
  selector: 'app-add-planner-details',
  templateUrl: './add-planner-details.component.html',
  styleUrls: ['./add-planner-details.component.scss']
})
export class AddPlannerDetailsComponent {
plannerForm : FormGroup;
constructor(
  private fb: FormBuilder,
  private dialogRef: MatDialogRef<AddPlannerDetailsComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private apiHelper: ApiHelper
) {
  this.plannerForm = this.fb.group({
    name: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    type : ['',Validators.required],
    preferredLanguages: ['',Validators.required],
    location: [''],
  });
}

savePlanner(){
  if (this.plannerForm.invalid){
    return 
  }

  const plannerData = this.plannerForm.value;

  this.apiHelper.post(plannerData,ApiEndPoints.createPlanner).subscribe((response)=>{
    if(response.data){
      this.dialogRef.close(true);
    }
  })
}

close() {
  this.dialogRef.close();
}
}
