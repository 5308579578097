<div class="container mt-4">
  <h5 class="mb-3 text-default-text-color text-uppercase  font-weight-bold m-0">Filter Purchases</h5>


  <div class="row mb-3">
    <div class="col-md-6">
      <label for="date" class="form-label">Filter by Date:</label>
      <select id="date" (change)="filterByDate($event)" class="form-select">
        <option value="">All Dates</option>
        <option *ngFor="let date of uniqueDates" [value]="date">{{ date }}</option>
      </select>
    </div>

    <div class="col-md-6">
      <label for="status" class="form-label">Filter by Status:</label>
      <select id="status" (change)="filterByStatus($event)" class="form-select">
        <option value="">All Statuses</option>
        <option *ngFor="let status of uniqueStatuses" [value]="status">{{ status }}</option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h5 class="text-default-text-color mb-3 text-uppercase  font-weight-bold m-0">Purchase List</h5>
      <div class="card-wrap bg-white">
      <table class="table table-hover table-border table-common">
        <thead class="thead-light">
          <tr>
            <th scope="col">Supplier Details</th>
            <th scope="col">Date</th>
            <th scope="col">Quantity</th>
            <th scope="col">Rate</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let purchase of filteredPurchases">
            <td>{{ purchase.supplierId }}</td>
            <td>{{ purchase.expectedDate }}</td>
            <td>{{ purchase.quantity }}</td>
            <td>{{ purchase.rate }}</td>
            <td>{{ purchase.status }}</td>
            <td>
              <a class="ms-3" (click)="viewPurchase(purchase.id)"> <i class="fa fa-eye text-color-secondary" aria-hidden="true"></i></a>
            </td>
          </tr>
          <tr *ngIf="filteredPurchases.length === 0">
            <td colspan="6" class="text-center">No purchases found</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</div>