import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-edit-builder',
  templateUrl: './edit-builder.component.html',
  styleUrls: ['./edit-builder.component.scss']
})
export class EditBuilderComponent {


  editBuilderForm!: FormGroup;
  builderId!: string;
  builderDetails: any;

  constructor(
    private route : ActivatedRoute,
    private router : Router,
    private toastr : ToastrService,
    private form : FormBuilder,
    public apiHelper : ApiHelper
  ){}

  ngOnInit():void {
    this.editBuilderForm = this.form.group({
      name : ['', Validators.required],
      phoneNumber: ['',Validators.required],
      preferredLanguages:['',Validators.required],
      location:['',Validators.required]
    });

    this.builderId = this.route.snapshot.paramMap.get('id')!;
    this.loadClientDetails(this.builderId)
  }

  loadClientDetails (id:string): void{
    this.apiHelper.post({id:id},ApiEndPoints.getBuilderDetails).subscribe((response)=>{
      this.builderDetails = response.data;
      console.log(this.builderDetails)
      this.setFormValues(this.builderDetails);
    });
  }

  setFormValues(data:any):void{
    this.editBuilderForm.patchValue({
      
      name:data.name,
      phoneNumber: data.phoneNumber,
      preferredLanguages: data.preferredLanguages
    });
  }

  onSubmit(): void {
    if(this.editBuilderForm.valid){
      const updatedValues = this.editBuilderForm.value;
      this.apiHelper.post({id:this.builderId,...updatedValues},ApiEndPoints.updateBuilder).subscribe(
        (response)=>{
          if(response.data)
            { this.toastr.success('Builder Details Have Been Updated');
            this.router.navigate(['/planners'])}
         
        },
        (error)=>{
          this.toastr.error("Failed to update project")
          console.error("Error Updating Builder:",error);
        },
      )
    }  else {
      this.toastr.warning('Please fill in all required fields');
    }
  }
}
