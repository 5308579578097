import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { PaginationService } from 'src/app/core/service/pagination.service';

interface InventoryItem {
  id: number;
  c1: string;
  c2: string;
  c3: string;
  finish: string;
  colour: string;
  rate: number;
  minimumQty: number;
  minimumOrderQty: number;
  vendor1: string;
  vendor2: string;
  days: string;
  blockedQty: number;
  sku: string;
  quantity: number;
}

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  createOrderForm!: FormGroup;
  inventoryItems: InventoryItem[] = [];
  filteredItems: InventoryItem[] = [];
  selectedStockStatus: string = 'all';
  searchTerm: string = '';
  selectedItem: InventoryItem | null = null;

  
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;

  constructor(
    public apiHelper: ApiHelper,
    private formBuilder: FormBuilder,
    private paginationService: PaginationService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createOrderForm = this.formBuilder.group({
      item: [{ value: '', disabled: true }, Validators.required],
      quantity: ['', Validators.required],
      suppliers: [[], Validators.required],
      status: ['enquired', Validators.required],
      date: ['', Validators.required]
    });

    this.getItems();
    this.filterItems();
  }

  get suppliers(): FormArray {
    return this.createOrderForm.get('suppliers') as FormArray;
  }

  isStockDataIncomplete(item: InventoryItem): boolean {
    return item.minimumQty == null || item.minimumOrderQty == null;
  }
  

  isLowStock(item: InventoryItem): boolean {
    if (this.isStockDataIncomplete(item)) {
      return false;
    }
    const quantity = Number(item.quantity);
    const minimumQty = Number(item.minimumQty);
    return quantity <= minimumQty;
  }

  filterItems(): void {
    let filtered = [...this.inventoryItems];
  
    // Apply stock status filter
    if (this.selectedStockStatus === 'low') {
      filtered = filtered.filter(item => this.isLowStock(item));
    } else if (this.selectedStockStatus === 'ok') {
      filtered = filtered.filter(item => !this.isLowStock(item));
    }
  
    // Normalize search term by removing spaces and converting to lowercase
    const normalizedSearchTerm = this.searchTerm.trim().replace(/\s+/g, '').toLowerCase();
  
    // Apply search term filter
    if (normalizedSearchTerm) {
      filtered = filtered.filter(item => {
        const c1Normalized = item.c1 ? item.c1.replace(/\s+/g, '').toLowerCase() : '';
        const c2Normalized = item.c2 ? item.c2.replace(/\s+/g, '').toLowerCase() : '';
  
        return c1Normalized.includes(normalizedSearchTerm) || c2Normalized.includes(normalizedSearchTerm);
      });
    }
  
    // If no items match the filters, show a warning
    if (filtered.length === 0) {
      this.toastr.warning('No components match the search term or stock status.');
    }
  
    // Update pagination
    this.totalPages = Math.ceil(filtered.length / this.itemsPerPage);
    this.filteredItems = filtered.slice(
      (this.currentPage - 1) * this.itemsPerPage,
      this.currentPage * this.itemsPerPage
    );
  }
  

  onPageChange(page: number): void {
    this.currentPage = page;
    this.filterItems();
  }





  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      console.log(this.currentPage)
      this.currentPage = page;
      this.filterItems();
    }
  }

  searchItems(): void {
    this.filterItems();
  }

  orderStock(item: InventoryItem): void {
    this.selectedItem = item;
    this.createOrderForm.patchValue({
      item: item.c2,
      quantity: item.minimumOrderQty,
      status: 'ordered'
    });

    
    const suppliers = [item.vendor1, item.vendor2];
    this.createOrderForm.patchValue({ suppliers });

    const modalElement = document.getElementById('orderModal');
    if (modalElement) {
      modalElement.style.display = 'block';
    }
  }

  closeModal(): void {
    const modalElement = document.getElementById('orderModal');
    if (modalElement) {
      modalElement.style.display = 'none';
    }
  }

  submitOrder(): void {
    if (!this.createOrderForm.value.quantity || this.createOrderForm.value.quantity <= 0) {
      alert('Please enter a valid quantity.');
      return;
    }

    if (!this.createOrderForm.value.suppliers || this.createOrderForm.value.suppliers.length === 0) {
      alert('Please select at least one supplier.');
      return;
    }
    if (!this.createOrderForm.value.date || this.createOrderForm.value.date.length === 0) {
      alert('Please provide an estimated date for delivery.');
      return;
    }

    if (!this.selectedItem) {
      alert('Please select an item from the inventory.');
      return;
    }

    const orderData = {
      itemId: this.selectedItem.id,
      quantity: this.createOrderForm.value.quantity,
      supplierIds: this.createOrderForm.value.suppliers,
      status: this.createOrderForm.value.status,
      expectedDate: this.createOrderForm.value.date
    };

    this.apiHelper.post(orderData, ApiEndPoints.createOrder).subscribe(
      (response) => {
        alert('Order placed successfully!');
        this.closeModal();
      },
      (error) => {
        console.error('Error placing order', error);
        alert('Failed to place the order. Please try again.');
      }
    );
  }

  getItems(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllItems).subscribe(
      (response) => {
        this.inventoryItems = response.data;
        console.log(this.inventoryItems)
        this.totalPages = Math.ceil(this.inventoryItems.length / this.itemsPerPage);
        this.filterItems();
      },
      (error) => {
        console.error('Error fetching inventory items', error);
      }
    );
  }
}
