<div class="d-flex justify-content-between mb-2 align-items-center">
    <h5 class="text-default-text-color text-uppercase  font-weight-bold m-0"></h5>
  
    <button type="button" class="btn brand-btn bg-color-secondary text-white" data-bs-toggle="modal" data-bs-target="#createComponentModal">
      <i class="fa fa-plus me-1" aria-hidden="true"></i> Add New Component
    </button>
    <!-- HTML !-->
  
  </div>
<div class="card">
    <div class="card-body">
      <!-- <h5>Add {{ influencerType }}</h5> -->
      <form [formGroup]="plannerForm">
        <!-- Basic Information Inputs -->
        <!-- <div class="form-group mb-3">
          <label for="name" class="mb-2">Name</label>
          <input
            id="name"
            formControlName="name"
            class="form-control"
          />
          <div *ngIf="plannerForm.get('name')?.invalid && plannerForm.get('name')?.touched">
            <small class="text-danger">Name is required</small>
          </div>
        
        </div> -->
        <div class="form-group mb-3">
            <label for="name" class="mb-2">Component</label>
            <ng-select
              id="name"
              formControlName="name"
              [items]="filteredOptions" 
              bindLabel="name"
              bindValue="name"
              placeholder="Select or type a name"
              [searchable]="true"
              [clearable]="true"
              class="form-control"
              (change)="onChangeV1($event)"
              (search)="onSearchChange($event)"
              (clear)=" onClear()"
              
            >
            </ng-select>
            
            <div *ngIf="plannerForm.get('name')?.invalid && plannerForm.get('name')?.touched">
              <small class="text-danger">Name is required</small>
            </div>
          </div>
          
        <!-- Supplier Information Section -->
        <div formArrayName="suppliers">
          <div
            *ngFor="let supplier of suppliers.controls; let i = index"
            [formGroupName]="i"
            class="mb-3 border p-3 rounded"
          >
            <h6>Supplier {{ i + 1 }}</h6>
            <div class="form-group mb-2">
              <label for="supplierName" class="mb-2">Supplier Name</label>
              <input
                id="supplierName"
                formControlName="name"
                class="form-control"
                placeholder="Enter Supplier Name"
              />
            </div>
  
            <div class="row">
              <div class="col-md-3 form-group mb-2">
                <label for="moq" class="mb-2">MOQ</label>
                <input
                  id="moq"
                  formControlName="moq"
                  type="number"
                  class="form-control"
                  placeholder="Enter MOQ"
                />
              </div>
  
              <div class="col-md-3 form-group mb-2">
                <label for="rate" class="mb-2">Rate</label>
                <input
                  id="rate"
                  formControlName="rate"
                  type="number"
                  class="form-control"
                  placeholder="Enter Rate"
                />
              </div>
  
              <div class="col-md-3 form-group mb-2">
                <label for="days" class="mb-2">Days</label>
                <input
                  id="days"
                  formControlName="days"
                  type="number"
                  class="form-control"
                  placeholder="Enter Days"
                />
              </div>
            </div>
  
            <!-- Remove Supplier Button -->
            <button
              type="button"
              class="btn btn-danger btn-sm mt-2"
              (click)="removeSupplier(i)"
            >
              Remove Supplier
            </button>
          </div>
          
        </div>
  
        <!-- Add Supplier Button -->
        <button
          type="button"
          class="btn btn-primary btn-sm mb-3"
          (click)="addSupplier()"
          *ngIf="suppliers.length <2"
        >
          Add Supplier
        </button>
  
        <hr />
        <div class="mt-3 d-flex justify-content-end">
          <button
            type="button"
            class="btn brand-btn btn-success"
            (click)="savePlanner()"
            [disabled]="plannerForm.invalid"
          >
            Save
          </button>
          <button
            type="button"
            class="btn brand-btn bg-red1 text-white ms-2"
            (click)="close()"
          >
            Close
          </button>
        </div>
      </form>
    </div>
  </div>

 <!-- Modal -->
<div class="modal fade" id="createComponentModal" tabindex="-1" aria-labelledby="createComponentModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createComponentModalLabel">Add New Components</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="createComponentForm">
            
            <!-- Category Dropdown -->
            <div class="mb-3">
              <label for="categorySelect" class="form-label">Category</label>
              <input type="text" class="form-control" id="category"   formControlName="category" />
            </div>
  
            <!-- Subcategory Dropdown -->
            <div class="mb-3">
              <label for="subcategorySelect" class="form-label">Subcategory</label>
              <input type="text" class="form-control" id="subcategory"  formControlName="subcategory"   />
            </div>
  
            <!-- Specification Input -->
            <div class="mb-3">
              <label for="specification" class="form-label">Specification</label>
              <input type="text" class="form-control" id="specification" formControlName="specification" />
            </div>
  
            <!-- Finish Input -->
            <div class="mb-3">
              <label for="finish" class="form-label">Finish</label>
              <input type="text" class="form-control" id="finish" formControlName="finish" />
            </div>
  
            <!-- Color Input -->
            <div class="mb-3">
              <label for="color" class="form-label">Color</label>
              <input type="text" class="form-control" id="color" formControlName="color" />
            </div>
  
          </form>
        </div>
  
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)="addNewComponent()">Create</button>
        </div>
      </div>
    </div>
  </div>
  


  
  
