<div class="container h-100">
  <div class="row p-2 h-100">
    <!-- Main Content Area: Search and Results -->
    <div class="col-md-7 col-sm-12">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="mb-3">Product Search</h5>
          <hr />
          <!-- new -->
          <form [formGroup]="searchForm" class="mb-2">
            <!-- Dropdown for selecting segment -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <h6 class="pb-2">Select Product Segment</h6>
                  <ng-select
                    [items]="segmentOptions"
                    bindLabel="segment"
                    placeholder="Search Product Segment"
                    formControlName="selectedSegment"
                    [searchable]="true"
                  >
                  </ng-select>
                </div>
              </div>

              <!-- Dropdown for selecting productType (shown only when segment is selected) -->
              <div
                class="form-group col-md-6"
                *ngIf="searchForm.get('selectedSegment')?.value"
              >
                <h6 class="pb-2">Select Product Type</h6>
                <ng-select
                  [items]="filteredOptions"
                  bindLabel="type"
                  placeholder="Search Product Type"
                  formControlName="selectedProductType"
                  [searchable]="true"
                  (search)="onSearchChange($event)"
                  (clear)="onClear()"
                >
                </ng-select>
              </div>
            </div>
          </form>

          <!-- Search Results Section -->

          <div
            class="scrollbar"
            *ngIf="searchForm.get('selectedProductType')?.value; else noResults"
          >
            <div
              *ngFor="let product of filteredProducts"
              class="d-flex align-items-center mb-1 p-2 border rounded"
            >
              <span class="flex-grow-1">{{ product.description }}</span>
              <span class="flex-grow-1">{{ product.subCategory }}</span>
              <span class="flex-grow-1">{{ product.finish }}</span>
              <span class="flex-grow-1">{{ product.colour }}</span>
              <button
                (click)="addProductV2(product.id)"
                class="btn btn-primary btn-sm"
              >
                Add
              </button>
            </div>
          </div>

          <!-- No Results Template -->
          <ng-template #noResults>
            <div *ngIf="filteredProducts.length === 0" class="text-center">
              <img
                src="./assets/images/no-data2.png"
                class="img-fluid noData2"
                alt="no data file"
              />
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Sidebar for Selected Products -->
    <div class="col-md-5 col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-3">Selected Products</h5>
          <hr />
          <div class="scrollbar">
            <!-- Iterate over newItems -->
            <div *ngFor="let item of newItems" class="mb-2">
              <!-- Iterate over each product in the item's product array -->
              <div
                *ngFor="let product of item.product"
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <h6
                  class="mb-0 h7 text-capitalize ellipsis"
                  matTooltip=" {{ product.segment }}, {{
                    product.productType
                  }}, {{ product.description }}"
                >
                  {{ product.productType }}, {{ product.description }}
                </h6>
                <div class="d-flex align-items-center">
                  <p
                    class="p-2 pb-0 pt-0 m-0 bg-light-blue soft d-flex align-items-center rounded"
                  >
                    {{ item.type }}
                  </p>
                  <i
                    class="fa fa-trash text-red1 p-2"
                    (click)="removeProduct(product)"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>

            <!-- No data found message -->
            <div *ngIf="newItems.length <= 0">
              <img
                src="./assets/images/no-data.png"
                class="img-fluid"
                alt="no data file"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end w-100 mt-3">
        <button
          class="btn btn-success"
          *ngIf="newItems.length > 0"
          (click)="closeDialogV2()"
          matTooltip="Info about the action"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
