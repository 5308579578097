import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';

@Component({
  selector: 'app-work-plan-create-modal',
  templateUrl: './work-plan-create-modal.component.html',
  styleUrls: ['./work-plan-create-modal.component.scss'],
})
export class WorkPlanCreateModalComponent {
  workPlanForm: FormGroup;
  itemsFromQuote: any[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<WorkPlanCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiHelper: ApiHelper
  ) {
    this.itemsFromQuote = data.items || [];

    this.workPlanForm = this.fb.group({
      assignedTo: ['', Validators.required],
      items: this.fb.array([]),
    });

    this.addItem(); // Optionally add one item by default
  }

  // Getter for items FormArray
  get items(): FormArray {
    return this.workPlanForm.get('items') as FormArray;
  }

  // Add an item to the form group
  addItem() {
    const itemGroup = this.fb.group({
      itemId: ['', Validators.required], // Dropdown will store the selected itemId
      quantity: [0, [Validators.required, Validators.min(1)]], // Quantity input
    });

    this.items.push(itemGroup);
  }

  // Remove an item
  removeItem(index: number) {
    this.items.removeAt(index);
  }

  // Get the maximum quantity available for the selected item
  getItemMaxQuantity(index: number): number {
    const selectedItemId = this.items.at(index).get('itemId')?.value;
    const selectedItem = this.itemsFromQuote.find(item => item.id === selectedItemId);
    return selectedItem ? selectedItem.qty : 0;
  }

  // Save Work Plan
  saveWorkPlan() {
    if (this.workPlanForm.invalid) {
      return;
    }

    // Transform form data to match API format
    const formData = this.workPlanForm.value;
    const transformedData = {
      assignedTo: formData.assignedTo,
      quoteListId: this.data.quoteListId,  // Include quoteListId
      productDetails: formData.items.map((item: any) => {
        const selectedItem = this.itemsFromQuote.find(i => i.id === item.itemId); // Find selected item
        return {
          code: selectedItem?.productInfo?.code || '', // Pass item code instead of itemId
          quantity: item.quantity,
        };
      }),
    };

    // Make API call to save the work plan
    this.apiHelper.post(transformedData, ApiEndPoints.createWorkPlan)
      .subscribe((response: any) => {
        if (response.data) {
          this.dialogRef.close(true); // Close the modal after successful save
        }
      });
  }

  // Close the modal
  close() {
    this.dialogRef.close();
  }
}
