<h5 class="text-default-text-color mb-3 text-uppercase  font-weight-bold m-0">Audit Tracking</h5>
<div class="card-wrap bg-white">
  <div class="table-responsive">
    <table class="table table-hover table-border table-common">
      <thead class="thead-light">
      <tr>
        <th>Type</th>
        <th>Name</th>
        <th>Client Name</th>
        <th>Client Phone</th>
        <th>Client Location</th>
        <th>Created By</th>
        <th>Created At</th>
        <th>Updated At</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let audit of auditData" class="table-row">
       
        <td>{{ audit.type }}</td>
        <td>{{ audit?.data?.name }}</td>
        <td>{{ audit?.data?.clientInfo?.name }}</td>
        <td>{{ audit?.data?.clientInfo?.phone }}</td>
        <td>{{ audit?.data?.clientInfo?.location }}</td>
        <td>{{ audit?.data?.createdBy }}</td>
        <td>{{ audit.createdAt | date:'medium' }}</td>
        <td>{{ audit.updatedAt | date:'medium' }}</td>

      </tr>
    </tbody>
  </table>
</div>
</div>