import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { FormBuilder, Validators } from '@angular/forms';

interface Purchase {
  id: string;
  supplierId: string;
  quantity: string;
  status: string;
  expectedDate: string;
  rate: string | null;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

interface Item {
  id: string;
  itemId: string;
  purchaseId: string;
  createdAt: string;
  updatedAt: string;

}

@Component({
  selector: 'app-purchase-details',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.scss']
})
export class PurchaseDetailsComponent implements OnInit {
  purchase: Purchase | null = null;
  items: Item[] = [];
  allItems: any[] = [];
  itemSuggestions: any[] = [];
  newItemId: string = '';
  newItemQuantity: string = '';
  openTop: boolean = false;

  constructor(
    private apiHelper: ApiHelper,
    private route: ActivatedRoute,
    private fb: FormBuilder, private elementRef: ElementRef
  ) {
  }
  @HostListener('window:resize', ['$event'])
  ngOnInit(): void {
    const purchaseId = this.route.snapshot.paramMap.get('id');
    if (purchaseId) {
      this.getPurchaseDetails(purchaseId);
    }
    this.getAllItems();
  }


  getPurchaseDetails(purchaseId: string): void {
    this.apiHelper.post({ id: purchaseId }, ApiEndPoints.getPurchaseDetails).subscribe(
      (response) => {
        if (response && response.data) {
          this.purchase = response.data.purchase;
          this.items = response.data.items;
        }
      },
      (error) => {
        console.error('Error fetching purchase details:', error);
      }
    );
  }


  getAllItems(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllItems).subscribe(
      (response) => {
        if (response && response.data) {
          this.allItems = response.data;
        }
      },
      (error) => {
        console.error('Error fetching all items:', error);
      }
    );
  }


  fetchItemSuggestions(): void {
    if (this.newItemId) {
      this.calculateDropdownPosition();

      const query = this.newItemId.toLowerCase();
      this.itemSuggestions = this.allItems.filter(item =>
        item.c1.toLowerCase().includes(query) ||
        item.c2.toLowerCase().includes(query) ||
        item.c3.toLowerCase().includes(query)
      );
    } else {
      this.itemSuggestions = [];
    }
  }

  calculateDropdownPosition() {
    const inputElement = this.elementRef.nativeElement.querySelector('input');
    const rect = inputElement.getBoundingClientRect();
    const spaceAbove = rect.top;
    const spaceBelow = window.innerHeight - rect.bottom;
    this.openTop = spaceBelow < 200 && spaceAbove > spaceBelow;
  }


  onResize() {
    this.calculateDropdownPosition();
  }

  selectItem(suggestion: any): void {
    this.newItemId = suggestion.id;
    this.itemSuggestions = [];
  }


  addItem(): void {
    if (this.newItemId && this.newItemQuantity) {
      const newItem = {
        itemId: this.newItemId,
        purchaseId: this.purchase?.id,
        quantity: this.newItemQuantity
      };
      this.apiHelper.post(newItem, ApiEndPoints.addItems).subscribe(
        (response) => {
          this.items.push(response.data);
          this.newItemId = '';
          this.newItemQuantity = '';
        },
        (error) => {
          console.error('Error adding item:', error);
        }
      );
    }
  }


  removeItem(itemId: string): void {
    this.apiHelper.post({ itemId }, ApiEndPoints.getAllItems).subscribe(
      () => {
        this.items = this.items.filter(item => item.id !== itemId);
      },
      (error) => {
        console.error('Error removing item:', error);
      }
    );
  }

  editPurchase() { }



  //  updatePurchase(): void {
  //   if (this.editPurchaseForm.valid && this.purchase) {
  //     const updatedPurchase = {
  //       ...this.purchase,
  //       ...this.editPurchaseForm.value
  //     };

  //     this.apiHelper.post(updatedPurchase, ApiEndPoints.updateOrder).subscribe(
  //       (response) => {
  //         if (response && response.data) {
  //           console.log('Purchase updated:', response.data);
  //           // Close modal after update
  //           const modalElement = document.getElementById('editPurchaseModal');
  //           const modalInstance = bootstrap.Modal.getInstance(modalElement);
  //           modalInstance.hide();
  //         }
  //       },
  //       (error) => {
  //         console.error('Error updating purchase:', error);
  //       }
  //     );
  //   }
  // }
}
