import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { DownloadQuotesPdfComponent } from '../quotes/download-quotes-pdf/download-quotes-pdf.component';
import { WorkPlanCreateModalComponent } from '../work-plan-create-modal/work-plan-create-modal.component';

@Component({
  selector: 'app-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.scss']
})
export class JobViewComponent implements OnInit {
  searchForm: FormGroup;
  jobId!: string;
  job: any;
  jobItems:any
  projectDetails: any = null;
  selectedItem: any = null;
  projectId!:string;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public apiHelper: ApiHelper,
    private toastr: ToastrService,
    private router: Router,
    
  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      this.jobId = id || '';
      if (id) {
        this.getJobDetails(id);
      }
    });
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }
  getJobDetails(id: string) {
    this.apiHelper.post({ id: id }, ApiEndPoints.getJobDetails).subscribe((response: any) => {
      if (response && response.data) {
        this.job = response.data.jobDetails;
        this.jobItems = response.data.jobItems; 
        this.projectId = this.job.project.id
        this.getProjectDetails(this.projectId); 
      }
    });
  }
  

  getProjectDetails(projectId: string) {
    this.apiHelper.post({ id: projectId }, ApiEndPoints.getProjectDetails).subscribe((response: any) => {
      if (response && response.data) {
        this.projectDetails = response.data.projectDetails;
      }
    });
  }

  selectItem(item: any) {
    this.selectedItem = item;

    if (this.selectedItem && this.selectedItem.id) {
      const quoteListId = this.selectedItem.id;

      this.apiHelper.post({ quoteListId: quoteListId }, ApiEndPoints.getItemComponent).subscribe(
        (response: any) => {
          if (response && response.data) {
            this.selectedItem.components = response.data;
          } else {
            this.toastr.error('Failed to fetch components.');
          }
        },
        (error) => {
          this.toastr.error('An error occurred while fetching components.');
        }
      );
    }
  }

  openPdfModal() {
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: {
         quoteItems: this.jobItems,
         quoteDetails: this.job
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('PDF Downloaded Successfully');
      }
    });
  }
  createWorkPlan() {
    const dialogRef = this.dialog.open(WorkPlanCreateModalComponent, {
      width: '10000px',
      height: '500px',
      data: {
         items: this.jobItems,
         jobDetails : this.job
        //  quoteDetails: this.job
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Work Plan Created Successfully');
      }
    });
  }
  viewWorkPlan() {
    this.router.navigate([`/view-work-plans/${this.jobId}`]);
  }  
  
}
