import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
@Component({
  selector: 'app-quote-revision-view',
  templateUrl: './quote-revision-view.component.html',
  styleUrls: ['./quote-revision-view.component.scss'],
})
export class QuoteRevisionViewComponent implements OnInit {
  quoteDetails: any[] = [];
  revisionDetails: any[] = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiHelper: ApiHelper
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getQuotesDetails(id);
      this.getRevisionDetails(id);
    }
  }
  getQuotesDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.getQuoteDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.quoteDetails.push(response.data.quoteDetails);
        }
      });
  }
  getRevisionDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.reviewDetailsGetV2)
      .subscribe((response) => {
        if (response && response.data) {
          this.revisionDetails.push(...response.data.reviewDetails);
        }
      });
  }
  showData(id: string) {
    this.router.navigate(['/quotes-view', id]);
  }
  revisionData(id: string) {
    this.router.navigate(['/revision-view', id]);
  }
}
