
<h5 *ngIf="!isObject(items)" class="text-default-text-color mb-3 text-uppercase  font-weight-bold m-0">Project Details</h5>
<ng-container *ngIf="isObject(items)">
  <div class="d-flex justify-content-between mb-2">
    <h5 class="text-default-text-color mb-3 text-uppercase  font-weight-bold m-0">Project Details</h5>
    <button type="button" class="btn brand-btn bg-color-secondary text-white" data-bs-toggle="modal" data-bs-target="#createQuoteModal" >
      <i class="fa fa-plus me-1" aria-hidden="true"></i>  Create Quote
    </button>

  </div>

  <!-- create quote modal  -->
  <div
    class="modal fade"
    id="createQuoteModal"
    tabindex="-1"
    aria-labelledby="createQuoteModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createQuoteModalLabel">
            Create New Quote
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="createQuoteForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
              <label for="quoteName" class="form-label">Quote Name</label>
              <input
                type="text"
                id="quoteName"
                class="form-control"
                formControlName="name"
                placeholder="Enter quote name"
              />
                  <div class="mb-3">
            <label for="companySelect" class="form-label">Company</label>
            <ng-select
              [items]="companies"
              bindLabel="name"
              bindValue="id"
              placeholder="Select or search for a Company"
              formControlName="companyId"
              [searchable]="true">
            </ng-select>
          </div>
          <div class="mb-3">
            <label for="regionSelect" class="form-label">Region</label>
            <ng-select
              [items]="regions"
              bindLabel="name"
              bindValue="id"
              placeholder="Select or search for a Region"
              formControlName="regionId"
              [searchable]="true">
            </ng-select>
          </div>
          
              <div class="mb-3 mt-3">
                <label for="quoteNumber" class="form-label">Quote Number</label>
                <input
                  type="text"
                  id="quoteNumber"
                  class="form-control"
                  formControlName="quoteNumber"
                />
              </div>
            </div>
            <div class="mb-3">
              <label for="quoteCreatedAt" class="form-label">Created At</label>
              <input
                type="date"
                id="quoteCreatedAt"
                class="form-control"
                formControlName="createdAt"
              />
            </div>
            
         
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                [disabled]="!createQuoteForm.valid"
              >
                Create Quote
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div class="col col-md-3"></div>
    <div class="col-md-6">
      <div class="card mb-2">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <p class="card-title">
             <strong> {{ items.projectDetails.name }}</strong>
            </p>
         
            <p class="card-text">
             
              <strong>Status:</strong>
              <span>
                {{
                  items.projectDetails.isActive
                }}
              </span>
            </p>
            <button [routerLink]="['/edit-project', items.projectDetails.id]" class="btn btn-primary ms-2">
              <i class="fa fa-edit"></i> Edit
            </button>
          </div>

          <!-- <p class="card-text">
            <strong>Company:</strong>
            {{ items.projectDetails.company?.name}}
          </p> -->

          <p class="card-text">
            {{ items.projectDetails.createdAt | date : "dd, MMM yyyy" }}
          </p>

          <!-- <p>
            <strong>Client Name:</strong>
            {{ items.projectDetails.clientInfo.name }}
          </p>
          <p>
            <strong>Phone:</strong> {{ items.projectDetails.clientInfo.phone }}
          </p>
          <p>
            <strong>Location:</strong>
            {{ items.projectDetails.clientInfo.location }}
          </p> -->
          <p>
            <strong>Client Name:</strong>
            {{ items.clientDetails?.name || items.projectDetails?.clientInfo?.name }}
          </p>
          <p>
            <strong>Phone:</strong> {{ items.clientDetails?.phoneNumber || items.projectDetails?.clientInfo?.phone ||  items.projectDetails?.clientInfo?.phoneNumber}}
          </p>
          <p>
            <strong>Project Location:</strong>
            {{ items.projectDetails?.location }}
          </p>
        </div>
      </div>
    </div>
    <div class="col col-md-3"></div>
  </div>

  <!-- <div class="row">
    <div class="col-md-3 col-sm-12" *ngFor="let quote of items.quoteDetails">
      <div class="card mb-3">
        <div class="card-body">
          <p class="card-title fw-bold">Quote Details:</p>
          <p class="card-text">{{ quote.name }}</p>
          <p class="card-text">{{ quote.createdAt | date : "dd, MMM yyyy" }}</p>
          <a (click)="$event.stopPropagation(); showData(quote.id)" class = "app-primary2">View</a>
        </div>
      </div>
    </div>
  </div> -->
  <!-- secondQuotesDeatils -->
  <div class="row">
    <div class="col-md-3 col-sm-12" *ngFor="let quote of items.quoteDetails">
      <div class="card mb-3">
        <div class="card-body">
          <p class="card-title fw-bold">Quote Details:</p>
          <p class="card-text">{{ quote.name }}</p>
          <p class="card-text">{{ quote.createdAt | date : "dd, MMM yyyy" }}</p>
          <a (click)="$event.stopPropagation(); quoteRevisionView(quote.id)" class = "app-primary2">View</a>
        </div>
      </div>
    </div>
  </div>
  <!-- reviewList  -->
   <!-- <h2>ReviewQuotes</h2>
  <div class="row">
    <div class="col-md-3 col-sm-12" *ngFor="let review of reviewDetails">
      <div class="card mb-3">
        <div class="card-body">
          <p class="card-title fw-bold">Review Details:</p>
          <p class="card-text">Review{{ review.revisionCount}}</p>
          <p class="card-text">Status : {{ review.status}}</p>
          <p class="card-text">createdAt: {{ review.createdAt | date : "dd, MMM yyyy" }}</p>
          <a (click)="$event.stopPropagation(); goToReviewListView(review.id)" class = "app-primary2">View</a>
        </div>
      </div>
    </div>
  </div> -->
</ng-container>
