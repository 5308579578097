<form [formGroup]="editProjectForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="name" class="form-label">Project Name</label>
      <input type="text" id="name" formControlName="name" class="form-control" required>
    </div>
    <div class="mb-3">
      <label for="location" class="form-label">Project Location</label>
      <input type="text" id="location" formControlName="location" class="form-control" required>
    </div>
    <!-- <div formGroupName="clientInfo">
      <div class="mb-3">
        <label for="location" class="form-label">Project Location</label>
        <input type="text" id="location" formControlName="location" class="form-control" required>
      </div>
    </div> -->
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save Changes</button>
    <a [routerLink]="['/project-details', projectId]" class="btn btn-secondary">Cancel</a>
  </div>
</form>
