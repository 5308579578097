import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-supplier',
  templateUrl: './update-supplier.component.html',
  styleUrls: ['./update-supplier.component.scss'],
})
export class UpdateSupplierComponent implements OnInit {
  supplierForm!: FormGroup;
  supplierName: string = '';
  componentName: string = '';
  componentDetails: string = '';
  moq: number = 0;
  rate: number = 0;
  days: number = 0;

  constructor(
    public dialogRef: MatDialogRef<UpdateSupplierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { supplierId: string; componentId: string },
    private fb: FormBuilder,
    private apiHelper: ApiHelper,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.supplierForm = this.fb.group({
      supplierName: [this.supplierName, [Validators.required]],
      moq: [this.moq, [Validators.required, Validators.min(0)]],
      rate: [this.rate, [Validators.required, Validators.min(0)]],
      days: [this.days, [Validators.required, Validators.min(0)]],
    });

    this.loadSupplierDetails();
  }

  loadSupplierDetails(): void {
    this.apiHelper
      .post({ id: this.data.supplierId }, ApiEndPoints.getByIdComponentSupplier)
      .subscribe(
        (response) => {
          if (response && response.data) {
            const supplier = response.data;
            this.supplierName = supplier.name;
            this.componentName = supplier.componentDetails.name;
            this.componentDetails= supplier.componentDetails;
            this.supplierForm.patchValue({
              supplierName: supplier.name,
              moq: supplier.moq,
              rate: supplier.rate,
              days: supplier.days,
            });
          } else {
            this.toastr.error('Supplier details not found.');
          }
        },
        () => {
          this.toastr.error('Error fetching supplier details.');
        }
      );
  }

  updateSupplierDetails(): void {
    const updatedData = {
      id: this.data.supplierId,
      name:this.supplierForm.value.supplierName,
      moq: this.supplierForm.value.moq,
      rate: this.supplierForm.value.rate,
      days: this.supplierForm.value.days,
    };

    this.apiHelper.post(updatedData, ApiEndPoints.updateComponentSupplier).subscribe(
      (response) => {
        if (response && response.data) {
          this.toastr.success('Supplier details updated successfully.');
          this.dialogRef.close(true);
        } else {
          this.toastr.error('Failed to update supplier details.');
        }
      },
      () => {
        this.toastr.error('Error occurred while updating supplier details.');
      }
    );
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
