
import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-download-quotes-pdf',
  templateUrl: './download-quotes-pdf.component.html',
  styleUrls: ['./download-quotes-pdf.component.scss']
})
export class DownloadQuotesPdfComponent {
  @ViewChild('tableContent', { static: false }) tableContent!: ElementRef;
  itemFromQuote: any[] = [];
  counter!: number
  length!: number
  pdf :any
  isGeneratingPdf : boolean = false;
  quoteDetails :any
  constructor(
    private dialogRef: MatDialogRef<DownloadQuotesPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ){
     this.itemFromQuote = data.quoteItems
     this.quoteDetails =data.quoteDetails
  }

  downloadpdfv2(){
    this.pdf = new jsPDF('p', 'mm', 'a4')
    this.length = this.itemFromQuote.length;
    this.counter = 0
    this.generatePdfBo();
  }

  
  //workingone
  generatePdfV8() {
    const pdfPageHeight = this.pdf.internal.pageSize.getHeight(); // PDF page height
    const imgWidth = 208; // Width for each item in the PDF
    let currentPosition = 30; // Start below headers on each page
  
    this.counter = 0; // Reset counter
  
    // Function to add headers to each page with proper alignment
    const addHeaders = () => {
      this.pdf.setFontSize(12);
      this.pdf.text("Sl.No", 5, 20); // Position for Sl.No
      this.pdf.text("Name", 30, 20); // Adjusted x position for each header
      this.pdf.text("Type", 90, 20);
      this.pdf.text("Description", 130, 20);
      this.pdf.text("Quantity", 170, 20);
      currentPosition = 30; // Set starting position for item content
    };
  
    // Recursive function to process each item
    const processItem = () => {
      const data: any = document.getElementById('pdf' + this.counter);
      if (!data) return;
  
      html2canvas(data, { scale: 2 }).then((canvas) => {
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
  
        // If adding this image would exceed the page, add a new page and headers
        if (currentPosition + imgHeight > pdfPageHeight) {
          this.pdf.addPage();
          addHeaders(); // Add headers at the start of each new page
        }
  
        // Add the image to the PDF below the headers
        this.pdf.addImage(contentDataURL, 'PNG', 0, currentPosition, imgWidth, imgHeight);
        currentPosition += imgHeight + 10; // Move down for the next item
        this.counter++;
  
        // Continue processing the next item
        if (this.counter < this.length) {
          processItem();
        } else {
          // Save the PDF when all items are processed
          this.pdf.save('users.pdf');
  
          // Show toaster notification on completion
          this.snackBar.open('PDF generation completed!', 'Close', { duration: 3000 });
        }
      });
    };
  
    // Show a toaster notification while generating the PDF
    this.snackBar.open('Generating PDF...', 'Close', { duration: 100000000000000});
  
    // Start by adding headers and processing items
    addHeaders();
    processItem();
  }

 
  generatePdfVTest() {
    const pdfPageHeight = this.pdf.internal.pageSize.getHeight(); // PDF page height
    const pdfPageWidth = this.pdf.internal.pageSize.getWidth(); // PDF page width
    let currentPosition = 30; // Start position below headers on each page
    const lineSpacing = 10; // Space between rows
    const startTime = Date.now();
    
    // Disable the button and show a snackbar while generating the PDF
    this.isGeneratingPdf = true;
    const snackBarRef = this.snackBar.open('Generating PDF, please wait...', 'Close');
    
    // Add headers for the table
    const addHeaders = () => {
      this.pdf.setFontSize(12);
      this.pdf.text("Sl.No", 10, currentPosition); // Position for Sl.No
      this.pdf.text("Name", 30, currentPosition);  // Adjusted x position for each header
      this.pdf.text("Type", 90, currentPosition);
      this.pdf.text("Description", 130, currentPosition);
      this.pdf.text("Quantity", 170, currentPosition);
      currentPosition += lineSpacing; // Move down for the first row of content
    };
    
    addHeaders(); // Initial headers for the first page
  
    // Loop through each item and add to the PDF
    this.itemFromQuote.forEach((item, index) => {
      // Calculate text line height dynamically based on content length
      const textLines = this.pdf.splitTextToSize(item.description || "N/A", pdfPageWidth - 130);
      const rowHeight = lineSpacing * textLines.length;
  
      // If the current position plus row height exceeds the page height, add a new page only if items are left
      if (currentPosition + rowHeight > pdfPageHeight) {
        this.pdf.addPage(); // Add a new page if content exceeds page height
        currentPosition = 30; // Reset position for the new page
        addHeaders(); // Add headers at the top of each new page
      }
  
      // Add row content with multi-line description handling
      this.pdf.text((index + 1).toString(), 10, currentPosition);
      this.pdf.text(item.productInfo?.name || "Empty", 30, currentPosition);
      this.pdf.text(item.type || "N/A", 90, currentPosition);
      this.pdf.text(textLines, 130, currentPosition); // Add multi-line description
      this.pdf.text(item.qty ? item.qty.toString() : "0", 170, currentPosition);
  
      currentPosition += rowHeight + lineSpacing; // Move down for the next row
    });
  
    // Save and download the PDF after all rows are processed
    this.pdf.save('users.pdf');
    this.isGeneratingPdf = false;
  
    // Close the initial snackbar and show completion message
    snackBarRef.dismiss();
    const generationTime = Date.now() - startTime;
    this.snackBar.open('PDF generation completed!', 'Close', { duration: generationTime + 1000 });
  }

  generatePdfBo() {
    const pdfPageHeight = this.pdf.internal.pageSize.getHeight(); // PDF page height
    const pdfPageWidth = this.pdf.internal.pageSize.getWidth(); // PDF page width
    let currentPosition = 30; // Start position below headers on each page
    const lineSpacing = 10; // Space between rows
    const startTime = Date.now();
  
    // Disable the button and show a snackbar while generating the PDF
    this.isGeneratingPdf = true;
    const snackBarRef = this.snackBar.open('Generating PDF, please wait...', 'Close');
  
    // Function to draw a border on the page
    const drawPageBorder = () => {
      this.pdf.setLineWidth(1); // Set the line width for the border
      this.pdf.rect(5, 5, pdfPageWidth - 10, pdfPageHeight - 10); // Draw rectangle with margin
    };
  
    // Add headers for the table
    const addHeaders = () => {
      this.pdf.setFontSize(12);
      this.pdf.text("Sl.No", 10, currentPosition); // Position for Sl.No
      this.pdf.text("Name", 30, currentPosition);  // Adjusted x position for each header
      this.pdf.text("Type", 90, currentPosition);
      this.pdf.text("Description", 130, currentPosition);
      this.pdf.text("Quantity", 170, currentPosition);
      currentPosition += lineSpacing; // Move down for the first row of content
    };
  
    // Draw the border for the first page
    drawPageBorder();
    addHeaders(); // Initial headers for the first page
  
    // Loop through each item and add to the PDF
    this.itemFromQuote.forEach((item, index) => {
      // Calculate text line height dynamically based on content length
      const textLines = this.pdf.splitTextToSize(item.description || "N/A", pdfPageWidth - 130);
      const rowHeight = lineSpacing * textLines.length;
  
      // If the current position plus row height exceeds the page height, add a new page only if items are left
      if (currentPosition + rowHeight > pdfPageHeight) {
        this.pdf.addPage(); // Add a new page if content exceeds page height
        currentPosition = 30; // Reset position for the new page
        drawPageBorder(); // Draw border for the new page
        addHeaders(); // Add headers at the top of each new page
      }
  
      // Add row content with multi-line description handling
      this.pdf.text((index + 1).toString(), 10, currentPosition);
      this.pdf.text(item.productInfo?.name || "Empty", 30, currentPosition);
      this.pdf.text(item.type || "N/A", 90, currentPosition);
      this.pdf.text(textLines, 130, currentPosition); // Add multi-line description
      this.pdf.text(item.qty ? item.qty.toString() : "0", 170, currentPosition);
  
      currentPosition += rowHeight + lineSpacing; // Move down for the next row
    });
  
    // Save and download the PDF after all rows are processed
    // this.pdf.save('users.pdf');
    this.pdf.save(`${this.quoteDetails.name||this.quoteDetails.jobName}.pdf`);
    this.isGeneratingPdf = false;
  
    // Close the initial snackbar and show completion message
    snackBarRef.dismiss();
    const generationTime = Date.now() - startTime;
    this.snackBar.open('PDF generation completed!', 'Close', { duration: generationTime + 1000 });
  }
  
      
  exportToExcel() {
    // Define the data with column headers similar to PDF
    const excelData = this.itemFromQuote.map((item, index) => ({
      "Sl.No": index + 1,
      "Name": item.productInfo?.name || "Empty",
      "Type": item.type || "N/A",
      "Description": item.description || "N/A",
      "Quantity": item.qty ? item.qty.toString() : "0"
    }));
  
    // Create a worksheet from the data
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
  
    // Create a workbook and add the worksheet to it
    const workbook: XLSX.WorkBook = {
      Sheets: { 'Data': worksheet },
      SheetNames: ['Data']
    };
  
    // Write the workbook to an Excel file
    // XLSX.writeFile(workbook, 'exportedData.xlsx');
    XLSX.writeFile(workbook, `${this.quoteDetails.name||this.quoteDetails.jobName}.xlsx`);
  }
  

   
  
  
  
 



generatePdfV6() {
  const pdfPageHeight = this.pdf.internal.pageSize.getHeight(); // PDF page height
  const imgWidth = 208; // Width for each item in the PDF
  let currentPosition = 30; // Start below headers on each page
  const lineHeight = 10; // Line height for each row
  const colWidths = [30, 60, 30, 70, 20]; // Column widths for the PDF

  this.counter = 0; // Reset counter

  // Function to add headers to each page with proper alignment
  const addHeaders = () => {
    this.pdf.setFontSize(8);
    this.pdf.text("Sl.No", 10, 20); // Position for Sl.No
    this.pdf.text("Name", 40, 20); // Adjusted x position for each header
    this.pdf.text("Type", 100, 20);
    this.pdf.text("Description", 140, 20);
    this.pdf.text("Quantity", 180, 20);
    currentPosition = 30; // Set starting position for item content
  };

  // Function to draw grid lines
  const drawGrid = (y: number) => {
    // Draw vertical lines
    let xOffset = 10; // Starting x position
    for (const width of colWidths) {
      this.pdf.line(xOffset, y, xOffset, y + lineHeight * this.itemFromQuote.length); // Vertical lines
      xOffset += width;
    }
    
    // Draw horizontal lines
    this.pdf.line(10, y, 10 + imgWidth, y); // Top line for current row
    this.pdf.line(10, y + lineHeight * this.itemFromQuote.length, 10 + imgWidth, y + lineHeight * this.itemFromQuote.length); // Bottom line for last row
  };

  // Recursive function to process each item
  const processItem = () => {
    const data: any = document.getElementById('pdf' + this.counter);
    if (!data) return;

    html2canvas(data, { scale: 2 }).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');

      // If adding this image would exceed the page, add a new page and headers
      if (currentPosition + imgHeight > pdfPageHeight) {
        this.pdf.addPage();
        addHeaders(); // Add headers at the start of each new page
        drawGrid(currentPosition); // Draw grid lines
      }

      // Add the image to the PDF below the headers
      this.pdf.addImage(contentDataURL, 'PNG', 0, currentPosition, imgWidth, imgHeight);
      currentPosition += imgHeight + 10; // Move down for the next item
      this.counter++;

      // Continue processing the next item
      if (this.counter < this.length) {
        processItem();
      } else {
        // Save the PDF when all items are processed
        this.pdf.save('users.pdf');
        this.snackBar.open('PDF generation completed!', 'Close', { duration: 3000 }); // Show toaster on completion
      }
    });
  };

  // Show a toaster notification while generating the PDF
  this.snackBar.open('Generating PDF...', 'Close', { duration: 10000000 });

  // Start by adding headers and processing items
  addHeaders();
  drawGrid(currentPosition); // Draw grid lines for the initial headers
  processItem();
}
generatePdf() {
  const pdfPageHeight = this.pdf.internal.pageSize.getHeight(); // PDF page height
  const imgWidth = 208; // Width for each item in the PDF
  let currentPosition = 30; // Start below headers on each page
  const lineHeight = 10; // Line height for each row
  const colWidths = [30, 60, 30, 70, 20]; // Column widths for the PDF

  this.counter = 0; // Reset counter

  // Function to add headers to each page with proper alignment
  const addHeaders = () => {
    this.pdf.setFontSize(12);
    this.pdf.text("Sl.No", 10, 20); // Position for Sl.No
    this.pdf.text("Name", 40, 20); // Adjusted x position for each header
    this.pdf.text("Type", 100, 20);
    this.pdf.text("Description", 140, 20);
    this.pdf.text("Quantity", 180, 20);
    
    // Draw grid lines for the header row
    this.pdf.line(10, 25, 10 + imgWidth, 25); // Top line for header row
    let xOffset = 10; // Starting x position
    for (const width of colWidths) {
      this.pdf.line(xOffset, 20, xOffset, 25); // Vertical lines for headers
      xOffset += width;
    }

    currentPosition = 30; // Set starting position for item content
  };

  // Recursive function to process each item
  const processItem = () => {
    const data: any = document.getElementById('pdf' + this.counter);
    if (!data) return;

    html2canvas(data, { scale: 2 }).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');

      // If adding this image would exceed the page, add a new page and headers
      if (currentPosition + imgHeight > pdfPageHeight) {
        this.pdf.addPage();
        addHeaders(); // Add headers at the start of each new page
      }

      // Add the image to the PDF below the headers
      this.pdf.addImage(contentDataURL, 'PNG', 0, currentPosition, imgWidth, imgHeight);
      currentPosition += imgHeight + 10; // Move down for the next item
      this.counter++;

      // Continue processing the next item
      if (this.counter < this.length) {
        processItem();
      } else {
        // Save the PDF when all items are processed
        this.pdf.save('users.pdf');
        this.snackBar.open('PDF generation completed!', 'Close', { duration: 3000 }); // Show toaster on completion
      }
    });
  };

  // Show a toaster notification while generating the PDF
  this.snackBar.open('Generating PDF...', 'Close', { duration: 10000000 });

  // Start by adding headers and processing items
  addHeaders();
  processItem();
}

generatePdfFromText() {
  // Show a toaster notification while generating the PDF
  this.snackBar.open('Generating PDF...', 'Close', { duration: 10000 });

  // Get the HTML content of the table
  const data = document.getElementById('tableId'); // Replace 'tableId' with your table's actual ID
  if (data) {
    // Extract text from the HTML element
    const rows = data.querySelectorAll('tr');
    let pdfContent = 'Sl.No\tName\tType\tDescription\tQuantity\n'; // PDF header

    rows.forEach(row => {
      const cells = row.querySelectorAll('td, th');
      const rowContent = Array.from(cells)
        .map(cell => cell ? cell.textContent?.trim() : '') // Check if cell is not null and then access textContent
        .join('\t'); // Tab-separated values
      pdfContent += rowContent + '\n'; // New line for each row
    });

    // Create a new PDF document
    const pdf = new jsPDF();
    pdf.setFontSize(12);
    pdf.text(pdfContent.split('\n'), 10, 10); // Start text at x=10, y=10

    // Save the PDF
    pdf.save('generated.pdf');

    // Show toaster notification on completion
    this.snackBar.open('PDF generation completed!', 'Close', { duration: 3000 });
  } else {
    // Show toaster notification if data is not found
    this.snackBar.open('No data found for PDF generation!', 'Close', { duration: 3000 });
  }
}
  
  
  close(){
    this.dialogRef.close();
  }



  

}
