import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {

  editProjectForm!: FormGroup;
  projectId!: string;
  projectDetails: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public apiHelper: ApiHelper
  ) {}

  ngOnInit(): void {
    this.editProjectForm = this.fb.group({
      name: ['', Validators.required],
      location: ['', Validators.required],
      // clientInfo: this.fb.group({
      //   name: ['', Validators.required],
      //   phone: ['', Validators.required],
      //   location: ['', Validators.required]
      // })
    });

    
    this.projectId = this.route.snapshot.paramMap.get('id')!;
    this.loadProjectDetails(this.projectId);
  }

  
  loadProjectDetails(id: string): void {
    this.apiHelper.post({ id: id }, ApiEndPoints.getProjectDetails).subscribe((response) => {
      this.projectDetails = response.data;
      this.setFormValues(this.projectDetails);
    });
  }

  
setFormValues(item: any): void {
  this.editProjectForm.patchValue({
    name: item.projectDetails.name,
    location :  item.projectDetails?.location,
    // clientInfo: {
    //   name:  item.projectDetails.clientInfo.name,
    //   phone:  item.projectDetails.clientInfo.phone,
    //   location:  item.projectDetails.clientInfo.location
    // }
  });
}


  
  onSubmit(): void {
    if (this.editProjectForm.valid) {
      const updatedItemDetails = this.editProjectForm.value;
      this.apiHelper.post({ id: this.projectId, ...updatedItemDetails }, ApiEndPoints.projectUpdate).subscribe(
        (response) => {
          this.toastr.success('Project details have been updated');
          this.router.navigate(['/project-details', this.projectId]);
        },
        (error) => {
          this.toastr.error('Failed to update project');
          console.error('Error updating item:', error);
        }
      );
    }
  }
}
