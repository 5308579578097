import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { jsPDF } from 'jspdf';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';
@Component({
  selector: 'app-download-quotes-pdf',
  templateUrl: './download-quotes-pdf.component.html',
  styleUrls: ['./download-quotes-pdf.component.scss'],
})
export class DownloadQuotesPdfComponent {
  @ViewChild('tableContent', { static: false }) tableContent!: ElementRef;
  itemFromQuote: any[] = [];
  counter!: number;
  length!: number;
  pdf: any;
  isGeneratingPdf: boolean = false;
  quoteDetails: any;
  constructor(
    private dialogRef: MatDialogRef<DownloadQuotesPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.itemFromQuote = data.quoteItems;
    this.quoteDetails = data.quoteDetails;
  }

 

  generatePdfBov() {
    const pdfPageHeight = this.pdf.internal.pageSize.getHeight(); // PDF page height
    const pdfPageWidth = this.pdf.internal.pageSize.getWidth(); // PDF page width
    let currentPosition = 30; // Start position below headers on each page
    const lineSpacing = 10; // Space between rows
    const startTime = Date.now();

    // Disable the button and show a snackbar while generating the PDF
    this.isGeneratingPdf = true;
    const snackBarRef = this.snackBar.open(
      'Generating PDF, please wait...',
      'Close'
    );

    // Function to draw a border on the page
    const drawPageBorder = () => {
      this.pdf.setLineWidth(1); // Set the line width for the border
      this.pdf.rect(5, 5, pdfPageWidth - 10, pdfPageHeight - 10); // Draw rectangle with margin
    };

    // Add headers for the table
    const addHeaders = () => {
      this.pdf.setFontSize(12);
      // this.pdf.text("Sl.No", 10, currentPosition); // Position for Sl.No
      // this.pdf.text("Name", 30, currentPosition);  // Adjusted x position for each header
      // this.pdf.text("Type", 90, currentPosition);
      // this.pdf.text("Description", 130, currentPosition);
      // this.pdf.text("Quantity", 170, currentPosition);
      this.pdf.text('Sl.No', 10, currentPosition); // Serial number
      this.pdf.text('Name', 30, currentPosition); // Name column
      this.pdf.text('Description', 90, currentPosition); // Adjust position after removing 'Type'
      this.pdf.text('Quantity', 140, currentPosition); // Move Quantity a bit to the left
      // Add new columns after Quantity
      this.pdf.text('Width', 180, currentPosition); // Width
      this.pdf.text('Height', 220, currentPosition); // Height
      this.pdf.text('Total Sq.ft', 260, currentPosition); // Total Sq.ft
      this.pdf.text('Total Cost', 310, currentPosition);
      currentPosition += lineSpacing; // Move down for the first row of content
    };

    // Draw the border for the first page
    drawPageBorder();
    addHeaders(); // Initial headers for the first page

    // Loop through each item and add to the PDF
    this.itemFromQuote.forEach((item, index) => {
      console.log(item);
      // Calculate text line height dynamically based on content length
      const textLines = this.pdf.splitTextToSize(
        item.description || 'N/A',
        pdfPageWidth - 130
      );
      const rowHeight = lineSpacing * textLines.length;

      // If the current position plus row height exceeds the page height, add a new page only if items are left
      if (currentPosition + rowHeight > pdfPageHeight) {
        this.pdf.addPage(); // Add a new page if content exceeds page height
        currentPosition = 30; // Reset position for the new page
        drawPageBorder(); // Draw border for the new page
        addHeaders(); // Add headers at the top of each new page
      }

      // Add row content with multi-line description handling
      // this.pdf.text((index + 1).toString(), 10, currentPosition);
      // this.pdf.text(item.productInfo?.name || 'Empty', 30, currentPosition);
      // this.pdf.text(item.type || 'N/A', 90, currentPosition);
      // this.pdf.text(textLines, 130, currentPosition); // Add multi-line description
      // this.pdf.text(item.qty ? item.qty.toString() : '0', 170, currentPosition);
      this.pdf.text((index + 1).toString(), 10, currentPosition); // Sl.No
      this.pdf.text(item.productInfo?.name || 'Empty', 30, currentPosition); // Name
      this.pdf.text(item.description || 'N/A', 90, currentPosition); // Description
      this.pdf.text(`${parseInt(item.qty || '0')}`, 140, currentPosition);
      this.pdf.text(
        `${parseFloat(item.width || 0).toFixed(2)}`,
        180,
        currentPosition
      );
      this.pdf.text(
        `${parseFloat(item.height || 0).toFixed(2)}`,
        220,
        currentPosition
      );
      this.pdf.text(
        `${parseFloat(item.totalSquareFeet || 0).toFixed(2)}`,
        260,
        currentPosition
      );
      this.pdf.text(
        `${parseFloat(item.totalCost || 0).toFixed(2)}`,
        310,
        currentPosition
      );

      currentPosition += rowHeight + lineSpacing; // Move down for the next row
    });

    // Save and download the PDF after all rows are processed
    // this.pdf.save('users.pdf');
    this.pdf.save(`${this.quoteDetails.name || this.quoteDetails.jobName}.pdf`);
    this.isGeneratingPdf = false;

    // Close the initial snackbar and show completion message
    snackBarRef.dismiss();
    const generationTime = Date.now() - startTime;
    this.snackBar.open('PDF generation completed!', 'Close', {
      duration: generationTime + 1000,
    });
  }

  generatePdf() {
    const doc = new jsPDF();

    // Define the headers for the table
    const head = [
      [
        'Sl.No',
        'Name',
        'Description',
        'Quantity',
        'Width',
        'Height',
        'Total Sq.ft',
        'Total Cost',
      ],
    ];

    // Prepare the table body from `itemFromQuote`
    const body = this.itemFromQuote.map((item, index) => [
      (index + 1).toString(),
      item.productInfo?.name || 'Empty',
      item.description || 'N/A',
      parseInt(item.qty || '0').toString(),
      parseFloat(item.width || 0).toFixed(2),
      parseFloat(item.height || 0).toFixed(2),
      parseFloat(item.totalSquareFeet || 0).toFixed(5),
      parseFloat(item.totalCost || 0).toFixed(2),
    ]);

    // Generate table using autoTable
    autoTable(doc, {
      head: head,
      body: body,
      startY: 20, // Adjust start position
      theme: 'grid',
      margin: { top: 10, bottom: 10 }, // Optional margin
      styles: { fontSize: 10, cellPadding: 3 }, // Adjust font and padding
      columnStyles: {
        2: { cellWidth: 40 }, // Adjust description width (index starts from 0)
        1: { cellWidth: 30 }, // Adjust name width
      },
      didDrawPage: (data) => {
        doc.setFontSize(14);
        doc.text(
          `${this.quoteDetails.name || this.quoteDetails.jobName}`,
          data.settings.margin.left,
          10
        ); // Add title
      },
    });

    // Save the generated PDF
    doc.save(`${this.quoteDetails.name || this.quoteDetails.jobName}.pdf`);
  }

  exportToExcel() {
    // Define the data with column headers similar to PDF
    const excelData = this.itemFromQuote.map((item, index) => ({
      'Sl.No': index + 1,
      Name: item.productInfo?.name || 'Empty',
      Description: item.description || 'N/A',
      Quantity: item.qty ? item.qty.toString() : '0',
      Width: item?.width,
      Height: item?.height,
      'Total Sq.Ft': item?.totalSquareFeet,
      'Total Cost': item?.totalCost,
    }));

    // Create a worksheet from the data
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);

    // Create a workbook and add the worksheet to it
    const workbook: XLSX.WorkBook = {
      Sheets: { Data: worksheet },
      SheetNames: ['Data'],
    };

    // Write the workbook to an Excel file
    // XLSX.writeFile(workbook, 'exportedData.xlsx');
    XLSX.writeFile(
      workbook,
      `${this.quoteDetails.name || this.quoteDetails.jobName}.xlsx`
    );
  }

  close() {
    this.dialogRef.close();
  }
}
