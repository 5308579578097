import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

import { AddClientDetailsComponent } from '../add-client-details/add-client-details.component';
import { ToastrService } from 'ngx-toastr';

interface ClientDetails {
  id: string;
  name: string;
  phoneNumber: string;
  projects: any[];
  preferredLanguages: string;
}

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {
  clientDetails: ClientDetails[] = [];
  filteredClientDetails: ClientDetails[] = [];
  paginatedClients: ClientDetails[] = []; // New array for paginated results

  // Pagination
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;
  
  // Search
  searchTerm: string = '';

  constructor(
    public apiHelper: ApiHelper,
    private dialog: MatDialog,
    private toaster : ToastrService
  ) {}

  ngOnInit(): void {
    this.getClients();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.paginateClients();
  }

  getClients(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllClients).subscribe(
      (response) => {
        this.clientDetails = response.data || [];
        this.filterClients(); // Initialize filtering and pagination after fetching data
      },
      (error) => {
        console.error('Error fetching clients', error);
      }
    );
  }

  filterClients(): void {
    let filtered = [...this.clientDetails];
    
    if (this.searchTerm.trim()) {
      filtered = filtered.filter(client =>
        client.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }

    this.totalPages = Math.ceil(filtered.length / this.itemsPerPage);
    this.filteredClientDetails = filtered;
    this.paginateClients(); // Paginate after filtering
  }

  paginateClients(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = this.currentPage * this.itemsPerPage;
    this.paginatedClients = this.filteredClientDetails.slice(startIndex, endIndex);
  }

  onSearch(): void {
    this.currentPage = 1; // Reset to first page when a new search is performed
    this.filterClients();
  }

  openClientCreate() {
    const dialogRef = this.dialog.open(AddClientDetailsComponent, {
      width: '600px',
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        this.toaster.success('Client Created Successfully')
        this.getClients(); // Refresh the client list after adding a new client
      }
      else if(result === false){
        this.toaster.warning('User is already Exists')
        this.getClients();
      }
      else{
        this.getClients();
      }
      
    });
  }
}
