import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Component } from '@angular/core';

interface TreeNode {
  name: string;
  children?: TreeNode[];
}

const TREE_DATA: TreeNode[] = [
  {
    name: 'Fruit',
    children: [
      { name: 'Apple' },
      { name: 'Orange' },
      { name: 'Banana' },
    ]
  }, 
  {
    name: 'Vegetables',
    children: [
      { name: 'Tomato' },
      { name: 'Potato' },
      { name: 'Onion' }
    ]
  }
];

@Component({
  selector: 'app-custom-tree',
  templateUrl: './custom-tree.component.html',
  styleUrls: ['./custom-tree.component.scss']
})
export class CustomTreeComponent {
  private _transformer = (node: TreeNode, level: number) => ({
    expandable: !!node.children && node.children.length > 0,
    name: node.name,
    level: level,
  });

  treeControl = new FlatTreeControl<TreeNodeFlat>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor() {
    this.dataSource.data = TREE_DATA;
  }

  hasChild = (_: number, node: TreeNodeFlat) => node.expandable;

  /** Handle checkbox toggle */
  toggleCheckbox(node: TreeNodeFlat) {
    node.checked = !node.checked;
    this.updateChildCheckboxes(node, node.checked);
  }

  /** Recursively update children checkboxes */
  updateChildCheckboxes(node: TreeNodeFlat, checked: boolean) {
    if (node.expandable) {
      this.treeControl.getDescendants(node).forEach(child => {
        child.checked = checked;
      });
    }
  }
}

interface TreeNodeFlat {
  expandable: boolean;
  name: string;
  level: number;
  checked?: boolean;
}
