<ng-container *ngIf="isObject(quote)">
  <div *ngIf="quoteCreated" class="row">
    <div class="col-md-8 col-sm-12 mb-3">
      <div
        class="mb-3 d-flex justify-content-between align-items-center flex-wrap"
      >
        <h4 class="text-app-primary2">Quote Name : {{ quote?.name }}</h4>
        <form [formGroup]="searchForm">
          <button class="btn btn-primary" (click)="openSearchDialog()">
            Add Items
          </button>
        </form>
      </div>
      <div class="card-wrap bg-white">
        <div class="table-responsive">
          <table class="table table-hover table-border table-common">
            <thead class="thead-light">
              <tr>
                <th>Sl.No</th>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of quote.items; let i = index"
                (click)="selectItem(item)"
                [class.table-active]="selectedItem === item"
                [class.border-primary]="selectedItem === item"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.productInfo?.name || "Empty" }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="item.qty"
                    min="0"
                    (input)="onInputChange($event, item)"
                  />
                </td>
                <td>
                  <button
                    class="btn btn-primary btn-sm me-3"
                    [ngClass]="{
                      'btn-success': !item.isQtyChanged,  
                      'btn-warning': item.isQtyChanged
                    }"
                    (click)="updateItemQuantity(item)"
                  >
                    Update
                  </button>
                  <button
                  class="btn btn-danger btn-sm "
                  (click)="confirmDelete(item.id)"
                >
                  Delete
                </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-end mb-3">
            <button
              class="btn brand-btn btn-primary btn-sm me-3"
              (click)="updateStatusSubmit(quoteId)"
            >
              send to Client
            </button>
            <button
              class="btn brand-btn btn-success btn-sm me-2"
              (click)="openPdfModal()"
            >
              Export Options
            </button>
            <button
            class="btn brand-btn btn-primary btn-sm me-3"
            (click)="previewsQuotes()"
          >
            Previous Quotes
          </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-md-4 col-sm-12">
   
      <h4 class="mb-3 text-app-primary2">Project Details</h4>
      <div class="mb-3" *ngIf="projectDetails">
        <div class="card-wrap bg-white">
          <div class="project-details">
            <div class="client-details">
              <h5 class="text-app-primary">Client Details</h5>
              <p class="mb-1">
                <strong>Project Name:</strong>
                {{ projectDetails.name || "N/A" }}
              </p>
              <p class="mb-1">
                <strong>Name:</strong>
                {{ projectDetails.clientInfo.name || "N/A" }}
              </p>
              <p class="mb-1">
                <strong>Address:</strong>
                {{ projectDetails.clientInfo.location || "N/A" }}
              </p>
              <p class="mb-1">
                <strong>Contact:</strong>
                {{ projectDetails.clientInfo.phone || "N/A" }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5 class="mb-3 text-app-primary2">Item Components</h5>

        <div class="card-wrap bg-white">
          <div *ngIf="selectedItem; else noItemSelected">
            <button
              class="btn btn-primary mt-3"
              (click)="openAddComponentDialog()"
            >
              Add Component
            </button>

            <div class="component-details pt-2" *ngIf="selectedItem.components && selectedItem.components.length > 0">
              <ul>
                <li *ngFor="let component of selectedItem.components">
                  <p><strong>Category:</strong> {{  component.product?.Category  }}</p>
                  <p><strong>Product Name:</strong> {{ component.product?.SubCategory  }}</p>
                  <p><strong>Description:</strong> {{  component.product?.Description }}</p>
                  <p><strong>Quantity:</strong> {{  component?.count }}</p> <!-- Updated quantity check -->
                         </li>
              </ul>
            </div>

            <div
              *ngIf="
                !selectedItem.components || selectedItem.components.length === 0
              "
            >
              <img
                src="./assets/images/no-data.png"
                class="img-fluid"
                alt="no data file"
              />
            </div>
          </div>

          <ng-template #noItemSelected>
            <p>Please click on an item to view its components.</p>
            <img
              src="./assets/images/no-data.png"
              class="img-fluid"
              alt="no data file"
            />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="quoteSumbited" class="row">
    <div class="col-md-8 col-sm-12 mb-3">
      <!-- <div 
        class="mb-3 d-flex justify-content-between align-items-center flex-wrap"
      >
        <h4 class="text-app-primary2">{{ quote?.name }}</h4>
        <form [formGroup]="searchForm">
          <button class="btn btn-primary" (click)="openSearchDialog()">
            Add Items
          </button>
        </form>
      </div> -->
      <div>
        <h4 class="text-app-primary2">Quote Name : {{ quote?.name }}</h4>
      </div>
      <div class="card-wrap bg-white">
        <div class="table-responsive">
          <table class="table table-hover table-border table-common">
            <thead class="thead-light">
              <tr>
                <th>Sl.No</th>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Quantity</th>
                <!-- <th>Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of quote.items; let i = index"
                (click)="selectItem(item)"
                [class.table-active]="selectedItem === item"
                [class.border-primary]="selectedItem === item"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.productInfo?.name || "Empty" }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.description }}</td>
                <!-- <td>
                  <input
                    type="number"
                    class="form-control"
                    [(ngModel)]="item.qty"
                    min="0"
                  />
                </td> -->
                <td>
                  {{ item.qty }}
                </td>
                <!-- <td>
                  <button
                    class="btn btn-primary btn-sm"
                    (click)="updateItemQuantity(item)"
                  >
                    Update
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-end mb-3">
            <!-- <button
              class="btn brand-btn btn-primary btn-sm me-3"
              (click)="openReviewCreate()"
            >
              Review
            </button> -->
            <button
              class="btn brand-btn btn-primary btn-sm me-3"
              (click)="confirmJob(quote.id)"
            >
              Confirm Job
            </button>
            <button
              class="btn brand-btn btn-primary btn-sm me-3"
              (click)="createAndSaveRevision()"
            >
              Create Revision Quote
            </button>
            <button
              class="btn brand-btn btn-success btn-sm me-2"
              (click)="openPdfModal()"
            >
              Export Options
            </button>
            <button
            class="btn brand-btn btn-primary btn-sm me-3"
            (click)="previewsQuotes()"
          >
            Previous Quotes
          </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
    
      <h4 class="mb-3 text-app-primary2">Project Details</h4>
      <div class="mb-3" *ngIf="projectDetails">
        <div class="card-wrap bg-white">
          <div class="project-details">
            <div class="client-details">
              <h5 class="text-app-primary">Client Details</h5>
              <p class="mb-1">
                <strong>Project Name:</strong>
                {{ projectDetails.name || "N/A" }}
              </p>
              <p class="mb-1">
                <strong>Name:</strong>
                {{ projectDetails.clientInfo.name || "N/A" }}
              </p>
              <p class="mb-1">
                <strong>Address:</strong>
                {{ projectDetails.clientInfo.location || "N/A" }}
              </p>
              <p class="mb-1">
                <strong>Contact:</strong>
                {{ projectDetails.clientInfo.phone || "N/A" }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h5 class="mb-3 text-app-primary2">Item Components</h5>

        <div class="card-wrap bg-white">
          <div *ngIf="selectedItem; else noItemSelected">
            <!-- <button
              class="btn btn-primary mt-3"
              (click)="openAddComponentDialog()"
            >
              Add Component
            </button> -->

            <div class="component-details pt-2" *ngIf="selectedItem.components && selectedItem.components.length > 0">
              <ul>
                <li *ngFor="let component of selectedItem.components">
                  <p><strong>Category:</strong> {{  component.product?.Category  }}</p>
                  <p><strong>Product Name:</strong> {{ component.product?.SubCategory  }}</p>
                  <p><strong>Description:</strong> {{  component.product?.Description }}</p>
                  <p><strong>Quantity:</strong> {{  component?.count }}</p> 
                         </li>
              </ul>
            </div>

            <div
              *ngIf="
                !selectedItem.components || selectedItem.components.length === 0
              "
            >
              <img
                src="./assets/images/no-data.png"
                class="img-fluid"
                alt="no data file"
              />
            </div>
          </div>

          <ng-template #noItemSelected>
            <p>Please click on an item to view its components.</p>
            <img
              src="./assets/images/no-data.png"
              class="img-fluid"
              alt="no data file"
            />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="quoteApproved" class="text-center mt-5">
    <p>Redirecting to job view...</p>
    <mat-spinner></mat-spinner>
  </div>
</ng-container>
