<div class="d-flex justify-content-between mb-2 align-items-center">
  <h5 class="text-default-text-color text-uppercase  font-weight-bold m-0">Project List</h5>

  <button type="button" class="btn brand-btn bg-color-secondary text-white" data-bs-toggle="modal" data-bs-target="#createProjectModal">
    <i class="fa fa-plus me-1" aria-hidden="true"></i> Create Project
  </button>
  <!-- HTML !-->

</div>

<div class=" mb-3 ">
  <div class="card-wrap bg-white">
    <div class="row">
      <div class="col-md-2 col-sm-12 search mb-1">
        <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="searchTerm" (input)="searchProjects()">
        <i class="fa fa-search ms-2"></i>
      </div>
  </div>
  
    <div class="mt-0 table-responsive">
      <table class="table table-hover table-border table-common">
        <thead class="thead-light">
          <tr>
            <th>Name</th>
            <th>Client Name</th>
            <th>Architect</th>
            <th>Contractor</th>
            <th>Consultant</th>
            <th>Location</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let project of filteredProjectData" class="table-row">
            <td>{{ project?.name }}</td>
            <!-- <td [title]="'Phone: ' + project?.clientInfo?.phone ">{{ project?.clientInfo?.name }}</td>
            <td [title]="'Type: ' + project?.planner?.type + ', Phone: ' + project?.planner?.phoneNumber">{{ project?.planner?.name }}</td> -->
            <!-- <td class="tooltip-container">
              {{ project?.clientInfo?.name }}
              <div class="custom-tooltip">
                <strong>Phone:</strong> {{ project?.clientInfo?.phone }}
              </div>
            </td>
            <td class="tooltip-container">
              {{ project?.planner?.name }}
              <div class="custom-tooltip">
                <strong>Type:</strong> {{ project?.planner?.type }}<br>
                <strong>Phone:</strong> {{ project?.planner?.phoneNumber }}
              </div>
            </td> -->
            <td class="tooltip-container">
              {{ project?.clientInfo?.name}}
              <div class="custom-tooltip">
                <strong>Phone:</strong> {{ project?.clientInfo?.phoneNumber ||  project?.clientInfo?.phone}}<br>
                <strong>languages:</strong> {{ project?.clientInfo?.preferredLanguages }}
                <div class="tooltip-arrow"></div>
              </div>
            </td>
            <td class="tooltip-container">
              {{ project?.planner?.name }}
              <div class="custom-tooltip">
                <strong>Type:</strong> {{ project?.planner?.type }}<br>
                <strong>Phone:</strong> {{ project?.planner?.phoneNumber}}<br>
                <strong>languages:</strong> {{ project?.planner?.preferredLanguages }}
                <div class="tooltip-arrow"></div>
              </div>
            </td>
            <td class="tooltip-container">
              {{ project?.contractor?.name }}
              <div class="custom-tooltip">
                <strong>Type:</strong> {{ project?.contractor?.type }}<br>
                <strong>Phone:</strong> {{ project?.contractor?.phoneNumber}}<br>
                <strong>languages:</strong> {{ project?.contractor?.preferredLanguages }}
                <div class="tooltip-arrow"></div>
              </div>
            </td>
            <td class="tooltip-container">
              {{ project?.consultant?.name }}
              <div class="custom-tooltip">
                <strong>Type:</strong> {{ project?.consultant?.type }}<br>
                <strong>Phone:</strong> {{ project?.consultant?.phoneNumber}}<br>
                <strong>languages:</strong> {{ project?.consultant?.preferredLanguages }}
                <div class="tooltip-arrow"></div>
              </div>
            </td>
            
            <td>{{ project?.location }}</td>
            <td>{{ project?.isActive }}</td>
            <td>
              <a [routerLink]="['/project-details', project.id]" class="ms-2">
                <i class="fa fa-eye text-color-secondary" aria-hidden="true"></i>

              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  

    <app-pagination [currentPage]="currentPage" [totalPages]="totalPages"  [itemsPerPage]="itemsPerPage"(pageChange)="onPageChange($event)">
    </app-pagination>
  

    
  </div>
</div>
<!-- Create Project Modal -->
<div class="modal fade" id="createProjectModal" tabindex="-1" aria-labelledby="createProjectModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="createProjectModalLabel">Create Project</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="createProjectForm">
          <div class="mb-3">
            <label for="projectName" class="form-label">Project Name</label>
            <input type="text" class="form-control" id="projectName" formControlName="name" />
          </div>

          <!-- Searchable Client Selection Dropdown -->
          <div class="mb-3">
            <label for="clientSelect" class="form-label">Client</label>
            <ng-select
              [items]="clients"
              bindLabel="name"
              placeholder="Select or search for a client"
              formControlName="clientInfo"
              [searchable]="true">
            </ng-select>
          </div>

          <!-- Searchable Architect/Builder Selection Dropdown -->
          <div class="mb-3">
            <label for="builderSelect" class="form-label">Architect</label>
            <ng-select
              [items]="builders"
              bindLabel="name"
              placeholder="Select or search for a Architects"
              formControlName="planner"
              [searchable]="true">
            </ng-select>
          </div>
          <div class="mb-3">
            <label for="builderSelect" class="form-label">Contractor</label>
            <ng-select
              [items]="contractor"
              bindLabel="name"
              placeholder="Select or search for a Contractors"
              formControlName="contractor"
              [searchable]="true">
            </ng-select>
          </div>
          <div class="mb-3">
            <label for="builderSelect" class="form-label">Consultant</label>
            <ng-select
              [items]="consultant"
              bindLabel="name"
              placeholder="Select or search for a Consultants"
              formControlName="consultant"
              [searchable]="true">
            </ng-select>
          </div>

          <div class="mb-3">
            <label for="location" class="form-label">Location</label>
            <input type="text" class="form-control" id="location" formControlName="location" />
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="submitProjectV2()">Create</button>
      </div>
    </div>
  </div>
</div>

  