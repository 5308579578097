import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  getTotalPages(items: any[], itemsPerPage: number): number {

    console.log(Math.ceil(items.length / itemsPerPage), 'Math.ceil(items.length / itemsPerPage)');
    return Math.ceil(items.length / itemsPerPage);

  }

  getPaginatedItems(items: any[], currentPage: number, itemsPerPage: number): any[] {

    const start = (currentPage - 1) * itemsPerPage;
    return items.slice(start, start + itemsPerPage);
    
  }
}
