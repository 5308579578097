import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent {
  
  editClientForm!: FormGroup;
  clientId!: string;
  clientDetails: any;

  constructor(
    private route : ActivatedRoute,
    private router : Router,
    private toastr : ToastrService,
    private form : FormBuilder,
    public apiHelper : ApiHelper
  ){}

  ngOnInit():void {
    this.editClientForm = this.form.group({
      name : ['', Validators.required],
      phoneNumber: ['',Validators.required],
      preferredLanguages:['',Validators.required]
    });

    this.clientId = this.route.snapshot.paramMap.get('id')!;
    this.loadClientDetails(this.clientId)
  }

  loadClientDetails (id:string): void{
    this.apiHelper.post({id:id},ApiEndPoints.getClientDetails).subscribe((response)=>{
      this.clientDetails = response.data;
      console.log(this.clientDetails)
      this.setFormValues(this.clientDetails);
    });
  }

  setFormValues(data:any):void{
    this.editClientForm.patchValue({
      
      name:data.name,
      phoneNumber: data.phoneNumber,
      preferredLanguages: data.preferredLanguages
    });
  }

  onSubmit(): void {
    if(this.editClientForm.valid){
      const updatedValues = this.editClientForm.value;
      this.apiHelper.post({id:this.clientId,...updatedValues},ApiEndPoints.clientUpdate).subscribe(
        (response)=>{
          if(response.data)
            { this.toastr.success('Client Details Have Been Updated');
            this.router.navigate(['/client-info'])}
         
        },
        (error)=>{
          this.toastr.error("Failed to update project")
          console.error("Error Updating Client:",error);
        },
      )
    }  else {
      this.toastr.warning('Please fill in all required fields');
    }
  }
}