import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-user-quotes',
  templateUrl: './user-quotes.component.html',
  styleUrls: ['./user-quotes.component.scss']
})
export class UserQuotesComponent implements OnInit {
  quotesData: any = [];
  jsonb: any;
  searchTerm: string = '';  

  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;

  constructor(public apiHelper: ApiHelper, public toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.getQuotes();
  }

  getQuotes(): void {
    this.apiHelper.post({}, ApiEndPoints.allquotes).subscribe((response) => {
      if (response && Array.isArray(response.data)) {
        this.quotesData = response.data;
        this.filterQuotes();  
      } else {
        this.toastr.error('Failed to load quotes');
      }
    });
  }

  showData(id: string): void {
    this.router.navigate(['/quotes-view', id]);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.filterQuotes();  
  }

  filterQuotes(): void {
    let filtered = [...this.quotesData];  

    if (this.searchTerm && this.searchTerm.trim()) {
      filtered = filtered.filter(item =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())  
      );
    }

    
    this.totalPages = Math.ceil(filtered.length / this.itemsPerPage);

    
    this.jsonb = filtered.slice(
      (this.currentPage - 1) * this.itemsPerPage,
      this.currentPage * this.itemsPerPage
    );
  }
}
