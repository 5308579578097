  <div class="d-flex justify-content-between mb-2 align-items-center">
    <h5 class="text-uppercase font-weight-bold m-0">Client List</h5>

    <button type="button" class="btn btn-primary" (click)="openClientCreate()">
      <i class="fa fa-plus me-1"></i> Add Client
    </button>
  </div>

  <div class="col-md-2 col-sm-12 search mb-3">
    <i class="fa fa-search ms-2"></i>

    <input 
      type="text" 
      class="form-control" 
      placeholder="Search by client name" 
      [(ngModel)]="searchTerm" 
      (input)="onSearch()"
    />
  </div>
  <div class="card-wrap bg-white">
    <div class="table-responsive">
  <table class="table table-hover table-border table-common">
    <thead class="thead-light">
      <tr>
        <th>Name</th>
        <th>Phone Number</th>
        <th>Preferred Languages</th>
        <th>Edit</th>
        
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let client of paginatedClients">
        <td>{{ client.name }}</td>
        <td>{{ client.phoneNumber }}</td>
        <td>{{ client.preferredLanguages }}</td>
        <td>
          <a [routerLink]="['/edit-client', client.id]" class="ms-2">
          <i class="fa fa-edit text-primary"></i>
        </a>
      </td>       
      </tr>
    </tbody>
    
  </table>
  </div>
  </div>

  <!-- Pagination Controls -->
  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="onPageChange(currentPage - 1)">Previous</a>
      </li>

      <li 
        class="page-item" 
        *ngFor="let page of [].constructor(totalPages); let i = index" 
        [class.active]="currentPage === i + 1"
      >
        <a class="page-link" (click)="onPageChange(i + 1)">{{ i + 1 }}</a>
      </li>

      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="onPageChange(currentPage + 1)">Next</a>
      </li>
    </ul>
  </nav>
