<h5 class="text-default-text-color mb-3 text-uppercase font-weight-bold m-0">Inventory List</h5>
<div class="card-wrap bg-white">
  <div class="row mb-3">
    <div class="col-md-2 col-sm-12 search">
      <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="searchTerm" (input)="searchItems()">
      <i class="fa fa-search ms-2"></i>
    </div>
    <div class="col-md-10 col-sm-12">
      <div class="d-flex justify-content-end align-items-center">
        <div class="form-group">
          <select id="stockStatus" class="form-control-sm" [(ngModel)]="selectedStockStatus" (change)="filterItems()">
            <option value="all">All</option>
            <option value="low">Low Stock</option>
            <option value="ok">OK Stock</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3 table-responsive table-hig">
    <table class="table table-hover table-border table-common">
      <thead class="thead-light">
        <tr>
          <th>No</th>
          <th>SKU</th>
          <th>Category</th>
          <th>Sub Category</th>
          <th>Specification</th>
          <th>Finish</th>
          <th>Colour</th>
          <th>Quantity</th>
          <th>Blocked Qty</th>
          <th>Rate</th>
          <th>MSQ</th>
          <th>MOQ</th>
          <th>V1</th>
          <th>V2</th>
          <th>Days</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filteredItems; let i = index" class="table-row">
          <td>{{ i + 1 }}</td>
          <td class="text-nowrap text-color-secondary">{{ item.sku }}</td>
          <td class="text-nowrap">{{ item.c1 }}</td>
          <td class="text-nowrap">{{ item.c2 }}</td>
          <td class="text-nowrap">{{ item.c3 }}</td>
          <td class="text-nowrap">{{ item.finish }}</td>
          <td class="text-nowrap">{{ item.colour }}</td>
          <td class="text-nowrap">{{ item.quantity }}</td>
          <td class="text-nowrap">{{ item.blockedQty }}</td>
          <td class="text-nowrap">{{ item.rate }}</td>
          <td class="text-nowrap">{{ item.minimumQty }}</td>
          <td class="text-nowrap">{{ item.minimumOrderQty }}</td>
          <td class="text-nowrap">{{ item.vendor1 }}</td>
          <td class="text-nowrap">{{ item.vendor2 }}</td>
          <td class="text-nowrap">{{ item.days }}</td>
          <td>
            <div class="d-flex justify-content-end">
              <!-- Show 'Incomplete Stock Data' if either minimumQty or minimumOrderQty is missing -->
              <span *ngIf="isStockDataIncomplete(item)" class="badge text-dark-red bg-light-red border border-0">
                Incomplete Stock Data
              </span>

              <!-- Show 'Purchase Stock' if stock is low -->
              <button *ngIf="!isStockDataIncomplete(item) && isLowStock(item)" class="badge text-dark-red bg-light-red border border-0"
                (click)="orderStock(item)">
                Purchase Stock
              </button>

              <!-- Show 'Stock OK' if stock level is fine -->
              <span *ngIf="!isStockDataIncomplete(item) && !isLowStock(item)" class="badge text-dark-green bg-light-green">
                Stock OK
              </span>

              <a [routerLink]="['/edit-item', item.id]" class="ms-2">
                <i class="fa fa-edit text-primary"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" [itemsPerPage]="itemsPerPage" (pageChange)="onPageChange($event)">
  </app-pagination>

</div>


<!-- Modal for creating an order -->
<div id="orderModal" class="modal" tabindex="-1" style="display: none;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Order Stock</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="createOrderForm" (ngSubmit)="submitOrder()">
          <div class="mb-3">
            <label for="item" class="form-label">Item</label>
            <input type="text" id="item" formControlName="item" class="form-control" readonly>
          </div>
          <div class="mb-3">
            <label for="quantity" class="form-label">Quantity</label>
            <input type="number" id="quantity" formControlName="quantity" class="form-control" required>
          </div>
          <div class="mb-3">
            <label for="date" class="form-label">Estimated Date</label>
            <input type="date" id="date" formControlName="date" class="form-control" required>
          </div>
          <div class="mb-3">
            <label for="status" class="form-label">Status</label>
            <select id="status" formControlName="status" class="form-control">
              <option value="ordered">Ordered</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div class="mb-3 bg-white">
            <label for="suppliers" class="form-label">Suppliers</label>
            <ng-select
              [items]="[{id: selectedItem?.vendor1, name: selectedItem?.vendor1}, {id: selectedItem?.vendor2, name: selectedItem?.vendor2}]"
              bindLabel="name" bindValue="id" formControlName="suppliers" [multiple]="true"
              placeholder="Select suppliers" class="form-select-lg mb-3" style="background-color: #f8f9fa;">
            </ng-select>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Submit Order</button>
            <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>