import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { SearchmodalComponent } from '../searchmodal/searchmodal.component';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { AddComponentModalComponent } from '../addcomponentmodal/addcomponentmodal.component';
import { WorkPlanCreateModalComponent } from '../../work-plan-create-modal/work-plan-create-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DownloadQuotesPdfComponent } from '../download-quotes-pdf/download-quotes-pdf.component';

@Component({
  selector: 'app-revision-view',
  templateUrl: './revision-view.component.html',
  styleUrls: ['./revision-view.component.scss'],
})
export class RevisionViewComponent implements OnInit {
  searchForm: FormGroup;
  projectDetails: any = null;
  selectedItem: any = null;

  reviewId!: string;
  // review: any;
  review:any = {
    items :[]
  }
  revisionSumbited: boolean = false;
  revisionCreated: boolean = false;
  revisionApproved: boolean = false;
  execute: boolean = true;
  isQtyChanged!: boolean;
  jobId: any;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public apiHelper: ApiHelper,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      this.reviewId = id || '';
      if (id) {
        this.getReviewListDetails(id);
      }
    });
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }
  getReviewListDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.revisionListGet)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.review = response.data.reviewDetails;
          this.review.items = response.data.reviewListDetails;
          this.review.items.forEach((item: { isQtyChanged: boolean }) => {
            item.isQtyChanged = false;
          })
          console.log(this.review.items)
          this.getProjectDetails(this.review.projectId);
          if (this.review.status === 'revisionSubmitted') {
            this.revisionSumbited = true;
          }
          if (this.review.status === 'revisionCreated') {
            this.revisionCreated = true;
          }
          if (this.review.status === 'revisionApproved') {
            this.revisionApproved = true;
          }
          if (this.revisionApproved) {
            this.apiHelper
            .post({ id: this.review.id }, ApiEndPoints.getRevisionJob)
            .subscribe((response: any) => {
              if (response && response.data) {
                this.jobId = response.data;
                this.router.navigate(['/job-view', this.jobId]);
              }
            });
          }
        }
      });
  }

  getProjectDetails(projectId: string) {
    this.apiHelper
      .post({ id: projectId }, ApiEndPoints.getProjectDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.projectDetails = response.data.projectDetails;
        }
      });
  }

  selectItem(item: any) {
    this.selectedItem = item;
  
    // Now, make an API call to fetch components for the selected item
    if (this.selectedItem && this.selectedItem.id) {
      const quoteListId = this.selectedItem.id;
      
      // API call to fetch components for the selected item (assuming the endpoint is getItemComponents)
      this.apiHelper
        .post({ quoteListId: quoteListId }, ApiEndPoints.getItemComponent)
        .subscribe((response: any) => {
          if (response && response.data) {
            console.log(response.data)
            // Store the components in selectedItem
            this.selectedItem.components = response.data;
          } else {
            this.toastr.error('Failed to fetch components.');
          }
        }, (error) => {
          this.toastr.error('An error occurred while fetching components.');
        });
    }
  }
  

  updateItemQuantity(item: any) {
    const updatedItem = { id: item.id, qty: item.qty };
    this.apiHelper.post(updatedItem, ApiEndPoints.revisionListUpdate).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.toastr.success('Quantity updated successfully!');
          item.isQtyChanged = false;
        } else {
          this.toastr.error('Failed to update quantity.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while updating quantity.');
      }
    );
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchmodalComponent, {
      width: '1000px',
      height: '500px',
      data: {
        searchTerm: this.searchForm.get('searchTerm')?.value,
        selectedItems: this.review.items,
        reviewId: this.review.id,
        id: this.review.quoteId,
        type: 'reviewQuotesView',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedItems) {
        result.selectedItems.forEach((item: any) => {
          this.review.items.push(item);
        });
      }
    });
  }
  openPdfModal() {
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: {
        quoteItems: this.review.items,
        quoteDetails : this.review
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Pdf Downloaded!');
      }
    });
  }

  openAddComponentDialog() {
    if (!this.selectedItem) {
      alert('Please select an item to add components.');
      return;
    }
  
    const dialogRef = this.dialog.open(AddComponentModalComponent, {
      width: '10000px',
      height: '500px',
      data: {
        itemId: this.selectedItem.id, // This is the quoteListId we're interested in
      },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedComponents) {
        // this.selectedItem.components = this.selectedItem.components || [];
        this.selectedItem.components.push(...result.selectedComponents); // Append new components to the selected item
  
        // Use selectedItem.id (quoteListId) in the payload
        this.apiHelper.post(
          { 
            quoteListId: this.selectedItem.id, // Pass quoteListId here
            components: this.selectedItem.components
          },
          ApiEndPoints.saveItemComponent
        ).subscribe(
          (response: any) => {
            if (response && response.data) {
              this.toastr.success('Component added and saved successfully!');
            } else {
              this.toastr.error('Failed to save component.');
            }
          },
          (error) => {
            this.toastr.error('An error occurred while saving the component.');
          }
        );
      }
    });
  }
  
  createAndSaveRevision() {
    const data = {
      quoteId: this.review.quoteId,
    };

    this.apiHelper.post(data, ApiEndPoints.revisionCreate, true).subscribe(
      (response) => {
        if (response && response.data) {
          const newRevisionId = response.data.reviewDetails.id;
          const revisionListData = {
            quoteId: this.review.quoteId,
            reviewListDetails: this.review.items,
            reviewId: newRevisionId,
          };
          this.apiHelper
            .post(revisionListData, ApiEndPoints.revisionListCreate, true)
            .subscribe((response) => {
              if (response && response.data) {
                this.review.items = [];
                this.review = {};
                this.revisionSumbited = false;
                this.router.navigate(['/revision-view', newRevisionId]);
              }
            });
        } else {
          this.toastr.error('Failed to create RevisisonQuotes.');
        }
      },
      (error: any) => {
        this.toastr.error(
          'An error occurred while creating the RevisionQuotes.'
        );
      }
    );
  }
  updateStatusSubmit(id: string) {
    const data = {
      id: id,
      status: 'revisionSubmitted',
    };
    this.apiHelper
      .post(data, ApiEndPoints.reviewUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Quote is Submit to Client');
          window.location.reload();
        } else {
          this.toastr.error('Error...!');
        }
      });
  }

  confirmJob(id: string) {
    const data = {
      id,
      status: 'revisionApproved',
    };
    this.apiHelper
      .post(data, ApiEndPoints.reviewUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Job Confirmed');
         this.createJobFromRevision(id)
        }
      });
  }
  createJobFromRevision(id: string) {
    const data = { id, entityType: "revision" };
  
    this.apiHelper
      .post(data, ApiEndPoints.createJobFromRevision, true)
      .subscribe(
        (response) => {
          if (response && response.data) {
            this.jobId = response.data.id;  // Store the jobId after successful creation
            const revisionListData = {
              reviewId: this.reviewId,
              jobItems: this.review.items,
              jobId: this.jobId,
            };
  
            // Now, create job items only after the job has been created successfully
            this.apiHelper
              .post(revisionListData, ApiEndPoints.createJobItems, true)
              .subscribe(
                (response) => {
                  if (response && response.data) {
                    this.router.navigate(['/job-view', this.jobId]);  // Navigate to the job view page
                    this.toastr.success('Job Items added and saved successfully!');
                  } else {
                    this.toastr.error('Failed to save Job Items.');
                  }
                },
                (error) => {
                  this.toastr.error('An error occurred while saving the Job Items.');
                }
              );
  
            this.toastr.success('Job Created from Quote');
            // window.location.reload(); // Reload after creating the job
          } else {
            this.toastr.error('Failed to create job from quote');
          }
        },
        (error) => {
          this.toastr.error('An error occurred while creating the job.');
        }
      );
  }
  

  previewsQuotes(){
    this.router.navigate(['/quote-revision-view',this.review.quoteId])
   }
   onInputChange(event: any, item: any) {
    const originalQty = event.target.value;

    if (originalQty !== item.qty) {
      item.isQtyChanged = true;
    } else {
      item.isQtyChanged = false;
    }
  }
  deleteItem(id:string){
    console.log(id);
    this.apiHelper.post({id:id},ApiEndPoints.revisionItemDelete,true).subscribe( (response: any) => {
      if (response && response.data) {
        this.toastr.success('Quantity Deleted successfully!');
        console.log( this.review.items)
        this.review.items = this.review.items.filter((item:any) => item.id !== id);console.log(this.review.item)
      } else {
        this.toastr.error('Failed to Delete the quantity.');
      }
    },
    (error) => {
      this.toastr.error('An error occurred while updating quantity.');
    })
  }
  confirmDelete(itemId: string) {
    if (confirm("Are you sure you want to delete this item?")) {
      this.deleteItem(itemId);
    }
  }
  // unwanted

  createRevision(id: string) {
    this.router.navigate(['/review-quotes-view', id]);
  }
}
