import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-work-plan-details',
  templateUrl: './view-work-plan-details.component.html',
  styleUrls: ['./view-work-plan-details.component.scss']
})
export class ViewWorkPlanDetailsComponent implements OnInit {
  workPlanId!: string;
  workPlanDetails: any;

  constructor(
    private route: ActivatedRoute,
    private apiHelper: ApiHelper,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    
    this.route.paramMap.subscribe((params) => {
      this.workPlanId = params.get('workPlanId') || '';
      if (this.workPlanId) {
        this.getWorkPlanDetails(this.workPlanId);
      }
    });
  }
  

  getWorkPlanDetails(workPlanId: string) {
    this.apiHelper.post({ workPlanId }, ApiEndPoints.getWorkPlanDetails).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.workPlanDetails = response.data;
        } else {
          this.toastr.error('Work plan details not found.');
        }
      },
      (error) => {
        this.toastr.error('Failed to load work plan details.');
      }
    );
  }
}
