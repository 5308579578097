import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { LoginPageComponent } from './modules/auth/pages/login-page/login-page.component';
import { ProfileViewComponent } from './modules/profile-view/profile-view.component';
import { AdminProjectsComponent } from './modules/admin/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './modules/admin/admin-users/admin-users.component';
import { AdminQuotesComponent } from './modules/admin/admin-quotes/admin-quotes.component';
import { UserQuotesComponent } from './modules/users/user-quotes/user-quotes.component';
import { UserProjectsComponent } from './modules/users/user-projects/user-projects.component';
import { QuotesViewComponent } from './modules/quotes/quotes-view/quotes-view.component';
import { InventoryComponent } from './modules/inventory/inventory.component';
import { AuditTrailComponent } from './modules/audit-trail/audit-trail.component';
import { EditItemComponent } from './modules/edit/edit-item/edit-item.component';
import { EditProjectComponent } from './modules/edit/edit-project/edit-project.component';
import { EditPurchaseComponent } from './modules/edit/edit-purchase/edit-purchase.component';
import { PurchaseDetailsComponent } from './modules/users/user-purchases/purchase-details/purchase-details.component';
import { PurchaseComponent } from './modules/users/user-purchases/purchase/purchase.component';
import { ProjectDetailsComponent } from './modules/users/user-projects/project-details/project-details.component';
import { ClientInfoComponent } from './modules/client-info/client-info.component';
import { BuildersArchitectsComponentComponent } from './modules/builders-architects-component/builders-architects-component.component';
import { EditClientComponent } from './modules/edit-client/edit-client.component';
import { EditBuilderComponent } from './modules/edit-builder/edit-builder.component';
import { QuoteRevisionViewComponent } from './modules/quotes/quote-revision-view/quote-revision-view.component';
import { RevisionViewComponent } from './modules/quotes/revision-view/revision-view.component';
import { JobsComponent } from './modules/jobs/jobs.component';
import { JobViewComponent } from './modules/job-view/job-view.component';
import { ArchitectsComponentComponent } from './modules/builders-architects-component/architects-component/architects-component.component';
import { ContractorsComponentComponent } from './modules/builders-architects-component/contractors-component/contractors-component.component';
import { ConsultantComponentComponent } from './modules/builders-architects-component/consultant-component/consultant-component.component';
import { ViewWorkPlanComponent } from './modules/quotes/view-work-plans/view-work-plans.component';
import { ViewWorkPlanDetailsComponent } from './modules/quotes/view-work-plan-details/view-work-plan-details.component';
import { ComponentSupplierComponent } from './modules/component_supplier/component-supplier/component-supplier.component';
import { ComponentSupplierViewComponent } from './modules/component_supplier/component-supplier-view/component-supplier-view.component';
import { UpdateSupplierComponent } from './modules/component_supplier/update-supplier/update-supplier.component';

// const routes: Routes = [
//   {
//     path: '',
//     redirectTo: '/login',
//     pathMatch: 'full',
//   },
//   {
//     path: 'user',
//     component: ContentLayoutComponent,
//     children: [
//       {
//         path: '',
//         redirectTo: 'user-projects',
//         pathMatch: 'full',
//       },
//       {
//         path: 'user-quotes',
//         component: UserQuotesComponent,
//       },
//       {
//         path: 'user-projects',
//         component: UserProjectsComponent,
//       },

//     ]
//   },
//   {
//     path: 'admin',
//     component: ContentLayoutComponent,
//     children: [
//       {
//         path: '',
//         redirectTo: 'admin-projects',
//         pathMatch: 'full',
//       },
//       {
//         path: 'admin-projects',
//         component: AdminProjectsComponent,
//       },
//       {
//         path: 'admin-users',
//         component: AdminUsersComponent,
//       },
//       {
//         path: 'admin-quotes',
//         component: AdminQuotesComponent,
//       },
//     ]
//   },
//   {
//     path: '',
//     component: ContentLayoutComponent,
//     children: [
//       {
//         path: '',
//         redirectTo: 'profile',
//         pathMatch: 'full',
//       },
//       {
//         path: 'project-details/:id',
//         component: ProjectDetailsComponent
//       },
//       {
//         path: 'quotes-view/:id',
//         component: QuotesViewComponent,
//       },
//       {
//         path: 'quote-revision-view/:id',
//         component: QuoteRevisionViewComponent
//       },

//       {
//         path: 'revision-view/:id',
//         component: RevisionViewComponent
//       },

//       {
//         path: 'profile',
//         component: ProfileViewComponent
//       },
//       {
//         path: 'inventory',
//         component: InventoryComponent
//       },
//       {
//         path: 'purchase',
//         component: PurchaseComponent
//       },

//       {
//         path: 'edit-item/:id',
//         component: EditItemComponent,
//       },
//       {
//         path: 'edit-project/:id',
//         component: EditProjectComponent,
//       },
//       {
//         path: 'edit-purchase/:id',
//         component: EditPurchaseComponent,
//       },
//       {
//         path: 'purchase-details/:id',
//         component: PurchaseDetailsComponent,
//       },
//       {
//         path: 'edit-client/:id',
//         component: EditClientComponent,
//       },
//       {
//         path: 'edit-builder/:id',
//         component: EditBuilderComponent,
//       },
//       {
//         path: 'job-view/:id',
//         component: JobViewComponent,
//       },
//       {
//         path: 'audit-tracking',
//         component: AuditTrailComponent,
//       },
//       {
//         path: 'client-info',
//         component: ClientInfoComponent,
//       },
//       {
//         path: 'planners',
//         component: BuildersArchitectsComponentComponent,
//       },
//       {
//         path: 'job',
//         component: JobsComponent,
//       },
//     ]
//   },
//   {
//     path: 'login',
//     component: LoginPageComponent
//   },
// ];

//
const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'user',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'user-projects',
        pathMatch: 'full',
      },
      {
        path: 'user-quotes',
        component: UserQuotesComponent,
      },
      {
        path: 'user-projects',
        component: UserProjectsComponent,
      },
    ],
  },
  {
    path: 'admin',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'admin-projects',
        pathMatch: 'full',
      },
      {
        path: 'admin-projects',
        component: AdminProjectsComponent,
      },
      {
        path: 'admin-users',
        component: AdminUsersComponent,
      },
      {
        path: 'admin-quotes',
        component: AdminQuotesComponent,
      },
    ],
  },
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
      },
      {
        path: 'project-details/:id',
        component: ProjectDetailsComponent,
      },
      {
        path: 'quotes-view/:id',
        component: QuotesViewComponent,
      },
      {
        path: 'quote-revision-view/:id',
        component: QuoteRevisionViewComponent,
      },

      {
        path: 'revision-view/:id',
        component: RevisionViewComponent,
      },

      {
        path: 'profile',
        component: ProfileViewComponent,
      },
      {
        path: 'inventory',
        component: InventoryComponent,
      },
      {
        path: 'purchase',
        component: PurchaseComponent,
      },

      {
        path: 'edit-item/:id',
        component: EditItemComponent,
      },
      {
        path: 'edit-project/:id',
        component: EditProjectComponent,
      },
      {
        path: 'edit-purchase/:id',
        component: EditPurchaseComponent,
      },
      {
        path: 'purchase-details/:id',
        component: PurchaseDetailsComponent,
      },
      {
        path: 'edit-client/:id',
        component: EditClientComponent,
      },
      {
        path: 'edit-builder/:id/:type',
        component: EditBuilderComponent,
      },
      {
        path: 'job-view/:id',
        component: JobViewComponent,
      },
      {
        path:'view-work-plans/:jobId',
        component:ViewWorkPlanComponent

      },
      {
        path:'work-plan-details/:workPlanId',
        component: ViewWorkPlanDetailsComponent
      },
      {
        path: 'componentsupplier',
        component: ComponentSupplierComponent,
      },
      {
        path: 'componentsupplierView',
        component: ComponentSupplierViewComponent,
      },
      {
        path: 'audit-tracking',
        component: AuditTrailComponent,
      },
      {
        path: 'client-info',
        component: ClientInfoComponent,
      },
    
      {
        path: 'architects',
        component: ArchitectsComponentComponent,
      },
      {
        path: 'contractors',
        component: ContractorsComponentComponent,
      },
      {
        path: 'consultant',
        component: ConsultantComponentComponent,
      },

      {
        path: 'job',
        component: JobsComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  { path: 'update-supplier/:componentId/:supplierId', component: UpdateSupplierComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
