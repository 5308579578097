<div class="container h-100 wider-modal">
  <div class="row p-2 h-100">
    <!-- Left Section: Update Supplier Form -->
    <div class="col-md-7 col-sm-12">
      <div class="card h-100">
        <div class="card-body">
          <h2 class="mb-3">Update Supplier Details: {{ supplierName }}</h2>
          <hr />
          <form [formGroup]="supplierForm">
               <!-- Supplier Name -->
               <div class="form-group mb-3">
                <label for="supplierName">Supplier Name:</label>
                <input
                  type="text"
                  id="supplierName"
                  formControlName="supplierName"
                  class="form-control"
                  placeholder="Enter supplier name"
                />
                <div
                  *ngIf="supplierForm.get('supplierName')?.hasError('required') && supplierForm.get('supplierName')?.touched"
                  class="text-danger"
                >
                  Supplier name is required.
                </div>
              </div>
            <!-- MOQ -->
            <div class="form-group mb-3">
              <label for="moq">Minimum Order Quantity (MOQ):</label>
              <input
                type="number"
                id="moq"
                formControlName="moq"
                class="form-control"
                placeholder="Enter MOQ"
                min="0"
              />
              <div
                *ngIf="
                  supplierForm.get('moq')?.hasError('required') &&
                  supplierForm.get('moq')?.touched
                "
                class="text-danger"
              >
                MOQ is required.
              </div>
            </div>

            <!-- Rate -->
            <div class="form-group mb-3">
              <label for="rate">Rate:</label>
              <input
                type="number"
                id="rate"
                formControlName="rate"
                class="form-control"
                placeholder="Enter rate"
                min="0"
              />
              <div
                *ngIf="
                  supplierForm.get('rate')?.hasError('required') &&
                  supplierForm.get('rate')?.touched
                "
                class="text-danger"
              >
                Rate is required.
              </div>
            </div>

       
            <div class="form-group mb-3">
              <label for="days">Days:</label>
              <input
                type="number"
                id="days"
                formControlName="days"
                class="form-control"
                placeholder="Enter days to delivery"
                min="0"
              />
              <div
                *ngIf="
                  supplierForm.get('days')?.hasError('required') &&
                  supplierForm.get('days')?.touched
                "
                class="text-danger"
              >
                Lead time is required.
              </div>
            </div>
          </form>

          <!-- Action Buttons -->
          <div class="d-flex justify-content-end mt-3">
            <button class="btn btn-secondary me-2" (click)="closeModal()">
              Close
            </button>
            <button
              class="btn btn-primary"
              (click)="updateSupplierDetails()"
              [disabled]="!supplierForm.valid"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Section: Component Details -->
    <div class="col-md-5 col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5>Component Details</h5>
          <div
            class="scrollable-container"
            style="
              overflow-y: auto; "
          >
            <div>
              <h6 class="h7 mb-1">
                Component Name: <strong>{{ componentName }}</strong>
              </h6>
      
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
