<div class="d-flex justify-content-between align-items-center mb-2">
  <h5 class="text-default-text-color text-uppercase font-weight-bold m-0">
    Component Suppliers
  </h5>
  <button
  type="button"
  class="btn brand-btn bg-color-secondary text-white"
  (click)="redirectToAddSupplier()"
>
  <i class="fa fa-plus me-1" aria-hidden="true"></i> Add Supplier
</button>
</div>

<div class="card-wrap bg-white">
  <div class="table-responsive">
<table class="table table-hover table-border table-common">
  <thead class="thead-light">
      <tr>
        <th>Component Name</th>
        <th>Supplier</th>
        <th>MOQ</th>
        <th>Rate</th>
        <th>Days</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let component of groupedComponents">
        <td>{{ component.name }}</td>
        <td>
          <select
            class="form-control"
            (change)="onSupplierChange($event, component.id)"
          >
            <option value="" disabled selected>Select a supplier</option>
            <option
              *ngFor="let supplier of component.suppliers"
              [value]="supplier.id"
            >
              {{ supplier.name }}
            </option>
          </select>
        </td>
        <td>
          {{
            selectedSupplierDetails[component.id].moq || " "
          }}
        </td>
        <td>
          {{
            selectedSupplierDetails[component.id].rate || " "
          }}
        </td>
        <td>
          {{
            selectedSupplierDetails[component.id].days || " "
          }}
        </td>
        <td>
          <a
          class="text-color-secondary"
            (click)="openUpdateSupplierModal(component.id, selectedSupplierDetails[component.id].supplierId)"
          >
          <i class="fa fa-eye" aria-hidden="true"></i>
        </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
