<div *ngIf="workPlans.length > 0; else noWorkPlans">
  <h3>Work Plans for Job: {{ jobId }}</h3>
  <div class="card-wrap bg-white">
    <div class="table-responsive">
      <table class="table table-hover table-border table-common">
        <thead class="thead-light">
          <tr>
            <th>S.No</th>
            <th>Assigned To</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
        
          <tr *ngFor="let workPlan of workPlans; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ workPlan.assignedTo|| "N/A" }}</td>
              <td>
                <button class="btn btn-link btn-sm" [routerLink]="['/work-plan-details', workPlan.id]">
                  <i class="fa fa-eye"></i></button>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #noWorkPlans>
  <div class="alert alert-warning">
    No work plans found for this job.
  </div>
</ng-template>
