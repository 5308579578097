<div class="bg-light dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
  <div class="container-md">
    <div class="justify-content-center row">
      <div class="col-lg-10 col-xl-8">
        <div class="card-group">
          <div class="card p-4">
            <div class="card-body">
              <div *ngIf="loginAccount">
                <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                  <h1>Login</h1>
                  <p class="text-body-secondary">Sign In to your account</p>
                  <div class="mb-3 input-group">
                    <span class="input-group-text">
                      <i class="fa fa-solid fa-user"></i>
                    </span>
                    <input placeholder="Phone Number" type="text" formControlName="phoneNumber" class="form-control">
                    <span *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)" class="text-danger">
                      <span *ngIf="loginForm.errors">This field is required</span>
                    </span>
                  </div>

                  <div class="mb-4 input-group">
                    <span class="input-group-text">
                      <i class="fa fa-solid fa-lock"></i>
                    </span>
                    <input placeholder="Password" type="password" formControlName="password" class="form-control">
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button color="primary" class="px-4 btn btn-primary" type="submit" [disabled]="loginForm.invalid">
                        Login
                      </button>
                    </div>
                    <div class="text-right col-6">
                      <button color="link" class="px-0 btn btn-link" type="button">
                        Forgot password?
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="signinAccount">
                <form [formGroup]="signUpForm" (ngSubmit)="onSignUpSubmit()">
                  <h1>Sign Up</h1>
                  <p class="text-body-secondary">Register your account</p>
                  <div class="mb-3 input-group">
                    <span class="input-group-text"> </span>
                    <input type="text" formControlName="phoneNumber" placeholder="Mobile Number" class="form-control" />
                  </div>
                  <div class="mb-3 input-group">
                    <span class="input-group-text"> </span>
                    <input placeholder="Name" type="text" formControlName="name" class="form-control" />
                  </div>


                  <div class="mb-4 input-group">
                    <span class="input-group-text"> </span>
                    <input placeholder="Password" type="password" formControlName="password" class="form-control" />
                  </div>
                  <div class="mb-4 input-group">
                    <span class="input-group-text"> </span>
                    <input placeholder="Confirm Password" type="password" formControlName="confirmPassword"
                      class="form-control" />
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button color="primary" class="px-4 btn btn-primary" type="submit"
                        [disabled]="signUpForm.invalid">
                        Sign Up
                      </button>
                    </div>
                    <!-- <div class="text-right col-6">
                      <button
                        (click)="accountChange()"
                        color="link"
                        class="px-0 btn btn-link"
                        type="button"
                      >
                        Login Account
                      </button>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card text-white bg-primary py-5">
            <div class="text-center card-body">
              <div>
                <h2>Project Flow Login</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <button color="primary" (click)="accountChange()" class="mt-3 active btn btn-primary" type="button">
                  Register Now!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>