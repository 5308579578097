<div [ngClass]="{'dark-theme' : darkLight.currentTheme === 'dark'}">
  <!--sidebar-->
  <div class="sidebar bg-app-primary h-100 position-fixed container-fluid" [ngClass]="{'side-nav-close':sidePanel}">
    <div class="border-bottom">
      <div class="logo p-2 d-flex justif-content-center w-100 align-items-center p-4 pb-3 pt-3" [routerLink]="[]">
        <img src="assets/images/projectflow-logo.png" *ngIf="!sidePanel" class="img-fluid" alt="logo" />
        <p class="h6 text-white2" *ngIf="sidePanel">P</p>
      </div>
    </div>

    <div class="side-menu text-nowrap pb-3" data-bs-spy="scroll" data-bs-target="#navbar-example">
      <div class="" id="navbar-example" *ngFor="let item of navItems">
        
        <p class="sub-name h6 text-app-muted fw-600 pb-3 m-0 pt-3 " *ngIf="!sidePanel">{{ item.name }}</p>
        <div class="mb-2" *ngFor="let subItem of item.subCat">
          <div class="side-main-menu">
            <a routerLink="{{ subItem.url }}" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              class="item d-flex align-items-center p-4 pt-1 pb-1">
              <i class="text-white2 {{ subItem.icon }}"></i>
              <span class="ps-2 text-white2 fw-400" *ngIf="!sidePanel">{{ subItem.subname }}</span>
            </a>
            <!-- <ul class="side-sub-menu ms-4">
              <li>Sub Title</li>
              <li>Sub Title</li>
              <li>Sub Title</li>
              <li>Sub Title</li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--main-->
  <div class="wrapper d-flex flex-column min-vh-100 sidebar-content sidebar-open"
    [ngClass]="{'side-nav-close':sidePanel}">

    <!--app-header-->
    <div class="d-print-none bg-white header header-sticky p-0 shadow-sm">
      <div class="container-fluid">
        <app-header (closeOpenoutput)="openClose()">
        </app-header>
      </div>
    </div>
    <!--app-body-->
    <div class="main-body bg-app-hash flex-grow-1 pt-3">
      <div class="h-auto container-fluid">

        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="d-print-none bg-white p-0 border-top footer">
      <div class="container-fluid">
        <app-footer></app-footer>
      </div>
    </div>

    <!--app footer-->
  </div>
</div>
