
  <h5 class="text-default-text-color text-uppercase  font-weight-bold mb-4">Purchase Details</h5>
  <div class="row">
    <div class="col-md-6">
      <div class="card-wrap bg-white">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="text-default-text-color">Purchase Information</h5>
          <button [routerLink]="['/edit-purchase', purchase?.id]" class="btn btn-primary ms-2 ">
            <i class="fa fa-edit"></i> Edit
          </button>
      </div>
      <ul class="list-group">
        <li class="list-group-item"><strong>Supplier:</strong> {{ purchase?.supplierId }}</li>
        <li class="list-group-item"><strong>Quantity:</strong> {{ purchase?.quantity }}</li>
        <li class="list-group-item"><strong>Status:</strong> {{ purchase?.status }}</li>
        <li class="list-group-item"><strong>Expected Date:</strong> {{ purchase?.expectedDate }}</li>
        <li class="list-group-item"><strong>Rate:</strong> {{ purchase?.rate || 'N/A' }}</li>
      </ul>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card-wrap bg-white">
      <h5 class="text-default-text-color">Items</h5>
      <table class="table table-border table-common">
        <thead class="thead-light">
          <tr>
            <th>Item ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td>{{ item.itemId }}</td>

            <td>
              <button class="btn btn-danger" (click)="removeItem(item.id)">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-md-6 col-sm-12">

          <div class=" position-relative">
            <label for="newItem">Add New Item:</label>
            <input type="search" [(ngModel)]="newItemId" id="newItem" class="form-control"
              (input)="fetchItemSuggestions()" placeholder="Enter Category, Subcategory, or Specifications">


            <div *ngIf="itemSuggestions.length > 0" [ngClass]="{'open-top': openTop, 'open-bottom': !openTop}" class="scrollbar dropdown mt-2 drop-scroll position-absolute w-100">
              <div class="force-overflow"></div>
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let suggestion of itemSuggestions" (click)="selectItem(suggestion)">
                  {{ suggestion.name }} - Category: {{ suggestion.c1 }}, Subcategory: {{ suggestion.c2 }},
                  Specifications: {{ suggestion.c3 }} (ID: {{ suggestion.id }})
                </li>
              </ul>
            </div>

          </div>
        </div>
        <div class="col-md-6 col-sm-12"> <label for="newItemQuantity">Quantity:</label>
          <input type="text" [(ngModel)]="newItemQuantity" id="newItemQuantity" class="form-control"
            placeholder="Enter Quantity">
        </div>
      </div>
    </div>

      <div class="mt-3 d-flex justify-content-end mb-2">
        <button class="btn btn-success mt-2" (click)="addItem()">Add Item</button>
      </div>

    </div>
  </div>

  <!-- <div class="modal fade" id="editPurchaseModal" tabindex="-1" aria-labelledby="editPurchaseModalLabel" aria-hidden="true"></div>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editPurchaseModalLabel">Edit Purchase</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="">
          <div class="mb-3">
            <label for="supplierId" class="form-label">Supplier ID</label>
            <input type="text" id="supplierId" class="form-control" formControlName="supplierId">
          </div>
          <div class="mb-3">
            <label for="quantity" class="form-label">Quantity</label>
            <input type="text" id="quantity" class="form-control" formControlName="quantity">
          </div>
          <div class="mb-3">
            <label for="status" class="form-label">Status</label>
            <input type="text" id="status" class="form-control" formControlName="status">
          </div>
          <div class="mb-3">
            <label for="expectedDate" class="form-label">Expected Date</label>
            <input type="date" id="expectedDate" class="form-control" formControlName="expectedDate">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="updatePurchase()">Save changes</button>
      </div>
    </div>
  </div> -->