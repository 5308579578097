import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';

@Component({
  selector: 'app-add-client-details',
  templateUrl: './add-client-details.component.html',
  styleUrls: ['./add-client-details.component.scss']
})
export class AddClientDetailsComponent {
  clientForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddClientDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiHelper: ApiHelper
  ) {
    this.clientForm = this.fb.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      preferredLanguages:['',Validators.required]
    });
  }

  // Save Client Data
  saveClient() {
    if (this.clientForm.invalid) {
      return;
    }

    const clientData = this.clientForm.value;

    // Make API call to save the client data
    this.apiHelper.post(clientData, ApiEndPoints.createClient)
      .subscribe((response: any) => {
        if (response.data) {
          this.dialogRef.close(true); // Close the modal after successful save
        }
      },
      (error) => { 
        // this.toastr.error(error.error?.message || "Unknown error");
        this.dialogRef.close(false)
      });
  }

  // Close the modal
  close() {
    this.dialogRef.close();
  }
}
