import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-edit-purchase',
  templateUrl: './edit-purchase.component.html',
  styleUrls: ['./edit-purchase.component.scss']
})
export class EditPurchaseComponent implements OnInit {

  editPurchaseForm!: FormGroup;
  purchaseId!: string;
  purchaseDetails: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public apiHelper: ApiHelper
  ) {}

  ngOnInit(): void {
    this.editPurchaseForm = this.fb.group({
      supplier: ['', Validators.required],
        quantity: ['', Validators.required],
        status: ['', Validators.required],
        expectedDate: ['', Validators.required],
        rate: ['', Validators.required],
    });

    
    this.purchaseId = this.route.snapshot.paramMap.get('id')!;
    this.loadPurchaseDetails(this.purchaseId);
  }

  
  loadPurchaseDetails(id: string): void {
    this.apiHelper.post({ id: id }, ApiEndPoints.getPurchaseDetails).subscribe((response) => {
      this.purchaseDetails = response.data;
      this.setFormValues(this.purchaseDetails);
    });
  }

  
setFormValues(item: any): void {
  this.editPurchaseForm.patchValue({
    supplier: item.purchase.supplierId,
      quantity:  item.purchase?.quantity,
      status:  item.purchase?.status,
      expectedDate:  item.purchase?.expectedDate,
      rate:  item.purchase?.rate
  });
}


  
  onSubmit(): void {
    if (this.editPurchaseForm.valid) {
      const updatedItemDetails = this.editPurchaseForm.value;
      this.apiHelper.post({ id: this.purchaseId, ...updatedItemDetails }, ApiEndPoints.updateOrder).subscribe(
        (response) => {
          this.toastr.success('Purchase details have been updated');
          this.router.navigate(['/purchase-details', this.purchaseId]);
        },
        (error) => {
          this.toastr.error('Failed to update purchase');
          console.error('Error updating item:', error);
        }
      );
    }
  }
}
