<div class="card card-body">

<div class=" mb-3 ">
    <!-- <div class="card-wrap bg-white">
      <div class="row">
        <div class="col-md-2 col-sm-12 search mb-1">
          <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="searchTerm" (input)="searchProjects()">
          <i class="fa fa-search ms-2"></i>
        </div>
    </div> -->
    
      <div class="mt-0 table-responsive table-hig">
        <table class="table table-hover table-border table-common">
          <thead class="thead-light">
            <tr>
              <th>SlNo</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let quote of quoteDetails; let i = index" class="table-row">
                <td>{{ i+1}}</td>
                <td>{{ quote?.name }}</td>
                <td>{{ quote?.status }}</td>
                <td>
                  <a (click)="$event.stopPropagation(); showData(quote.id)"> 
                    <i class="fa fa-eye text-color-secondary" aria-hidden="true"></i>
                  </a>
                </td>
              </tr> 
            <!-- <tr *ngFor="let project of filteredProjectData" class="table-row">
              <td>{{ project?.name }}</td>
              <td>{{ project?.clientInfo?.name }}</td>
              <td>{{ project?.clientInfo?.phone }}</td>
              <td>{{ project?.clientInfo?.location }}</td>
              <td>{{ project?.isActive }}</td>
              <td>
                <a [routerLink]="['/project-details', project.id]" class="ms-2">
                  <i class="fa fa-eye text-color-secondary" aria-hidden="true"></i>
  
                </a>
              </td>
            </tr> -->
            
             <tr *ngFor="let revision of revisionDetails;let i = index" class="table-row">
              <td>{{ i+2 }}</td>
              <td>{{ revision?.name }}</td>
              <td>{{ revision?.status}}</td>
              <td>
                <a (click)="$event.stopPropagation(); revisionData(revision.id)"> 
                  <i class="fa fa-eye text-color-secondary" aria-hidden="true"></i>
                </a>
              </td>
            </tr> 
            
          </tbody>
        </table>
      </div>
    
  
      <!-- <app-pagination [currentPage]="currentPage" [totalPages]="totalPages"  [itemsPerPage]="itemsPerPage"(pageChange)="onPageChange($event)">
      </app-pagination> -->
    
  
      
    </div>

  </div>