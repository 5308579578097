// import { Component, OnInit } from '@angular/core';
// import { navItems } from '../nav';
// import { LocalstorageService } from 'src/app/core/service/localstorage.service';
// import { ToastrService } from 'ngx-toastr';

// @Component({
//   selector: 'app-content-layout',
//   templateUrl: './content-layout.component.html',
//   styleUrls: ['./content-layout.component.scss'],
// })
// export class ContentLayoutComponent implements OnInit{
//   public navItems = navItems;
//   sidePanel: boolean = false;
//   // darkLight = ''
//   constructor(public darkLight: LocalstorageService, private toastr : ToastrService){
  
//   }
//   ngOnInit(): void {
//     this.darkLight.currentTheme
//   }
//   openClose() {
//     this.sidePanel = !this.sidePanel
//   }

// }
//
import { Component, OnInit } from '@angular/core';
import { navItems } from '../nav';
import { LocalstorageService } from 'src/app/core/service/localstorage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit{ 
  public navItems = navItems;
  sidePanel: boolean = false;

  constructor(public darkLight: LocalstorageService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.darkLight.currentTheme;
    // Initialize `showSubOptions` for "Influencer" in the `navItems`
    this.navItems.forEach(item => {
      item.subCat?.forEach(subItem => {
        if (subItem.subname === 'Influencer') {
          subItem.showSubOptions = false; // Default to hidden initially
        }
      });
    });
  }

  openClose() {
    this.sidePanel = !this.sidePanel;
  }

  toggleSubOptions(subItem: any, event: Event): void {
  console.log(event );
  
    if (subItem.subname === 'Influencer') {
      event.preventDefault();
      // Toggle visibility for Influencer sub-options
      subItem.showSubOptions = !subItem.showSubOptions;
    }
  }
}



