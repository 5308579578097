import { Component, OnInit } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

interface AuditData {
  id : string;
  entityId:string;
  type : string;
  data:{
    name:string;
    createdBy:string;
    clientInfo: {
      name: string,
      phone: string,
      location: string
  }
  }
  date: string;
  actor:string;
  createdAt:string;
  updatedAt:string;  
}


@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {
  auditData: AuditData[] =[]; // Define this to hold the response data

  constructor(
    public apiHelper: ApiHelper,
    private router: Router,
    public toastr: ToastrService,
  ){}
  ngOnInit(): void {
    this.getAuditData(); // Fetch the audit data when the component initializes
  }


  getAuditData(): void {
    this.apiHelper.post({}, ApiEndPoints.auditData).subscribe((response) => {
      if (response && response.data) {
        this.auditData = response.data;
      } else {
        this.toastr.error('Failed to load audit data.');
      }
    });
  }

}
