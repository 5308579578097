<nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="changePage(currentPage - 1)">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </a>
      </li>
  
      <li class="page-item" *ngFor="let page of getPaginationPages()" [class.active]="page === currentPage" [class.disabled]="page === -1">
        <ng-container *ngIf="page !== -1">
          <a class="page-link" (click)="changePage(page)">{{ page }}</a>
        </ng-container>
        <ng-container *ngIf="page === -1">
          <span class="page-link">...</span>
        </ng-container>
      </li>
  
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="changePage(currentPage + 1)">
          <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </a>
      </li>
    </ul>
  </nav>
  