import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { AddPlannerDetailsComponent } from '../../add-planner-details/add-planner-details.component';

interface PlannerDetails {
  id:string;
  name: string;
  phoneNumber: string;
  projects: any[];
  preferredLanguages : string;
  location : any [];
}

@Component({
  selector: 'app-contractors-component',
  templateUrl: './contractors-component.component.html',
  styleUrls: ['./contractors-component.component.scss']
})
export class ContractorsComponentComponent {

    plannerDetails: PlannerDetails[] = [];
    filteredPlannerDetails: PlannerDetails[] = [];
    paginatedPlanners: PlannerDetails[] = []; // New array for paginated results
  
    // Pagination
    currentPage: number = 1;
    itemsPerPage: number = 10;
    totalPages: number = 1;
  
    // Search
    searchTerm: string = '';
  
    constructor(public apiHelper: ApiHelper, private dialog: MatDialog) {}
  
    ngOnInit(): void {
      this.getPlanners();
    }
  
    onPageChange(page : number) : void { 
      this.currentPage = page ;
      this.paginatePlanners();
    }
  
  
    getPlanners(): void {
  
      this.apiHelper.post({type :'Contractor'},ApiEndPoints.getAllBuilders).subscribe(
        (response)=> { 
          this.plannerDetails = response.data || [];
          console.log(this.plannerDetails)
          this.filterPlanners();
        },
        (error) => {
          console.error("Error fetching Contractor ",error)
        }
      )
    }
  
    filterPlanners() : void {
      let filtered = [ ...this.plannerDetails];
  
      if(this.searchTerm.trim()){
        filtered = filtered.filter(planner =>
          planner.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      this.totalPages = Math.ceil(filtered.length/ this.itemsPerPage);
      this.filteredPlannerDetails = filtered;
      this.paginatePlanners()
    }
  
  
    paginatePlanners(): void{
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage* this.itemsPerPage;
      this.paginatedPlanners = this.filteredPlannerDetails.slice(startIndex,endIndex);
    }
  
    onSearch(): void {
      this.currentPage = 1
      this.filterPlanners()
    }
  
    openPlannerCreate(){
      const dialogRef = this.dialog.open(AddPlannerDetailsComponent,{
        width : '600px',
        data: {
          type : 'Contractor'
        },
      });
      dialogRef.afterClosed().subscribe((result:any) => {
        if(result){
          this.getPlanners();
        }
      })
    }
  
}
