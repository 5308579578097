<div class="card">
    <div class="card-body">
        <h5>Add {{influencerType}}</h5>
        <hr />
        <form [formGroup]="plannerForm">
            <div class="form-group mb-2">
                <label for="name" class="mb-2">Name</label>
                <input
                    id="name"
                    formControlName="name"
                    class="form-control"
                    placeholder="Enter {{influencerType}} name"
                />
                <div *ngIf="plannerForm.get('name')?.invalid && plannerForm.get('name')?.touched">
                    <small class="text-danger">Name is required</small>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="phoneNumber" class="mb-2">Phone Number</label>
                <input
                    id="phoneNumber"
                    formControlName="phoneNumber"
                    class="form-control"
                    placeholder="Enter phone number"
                />
                <div *ngIf="plannerForm.get('phoneNumber')?.invalid && plannerForm.get('phoneNumber')?.touched">
                    <small class="text-danger">Phone number is required</small>
                </div>
            </div>

            <!-- Selective input for Type -->
            <!-- <div class="form-group mb-2">
                <label for="type" class="mb-2">Type</label>
                <select
                    id="type"
                    formControlName="type"
                    class="form-control"
                >
                    <option value="" disabled>Select Type</option>
                    <option value="Constructor">Constructor</option>
                    <option value="Architect">Architect</option>
                </select>
                <div *ngIf="plannerForm.get('type')?.invalid && plannerForm.get('type')?.touched">
                    <small class="text-danger">Type is required</small>
                </div>
            </div> -->

            <div class="form-group mb-2">
                <label for="preferredLanguages" class="mb-2">Preferred Languages</label>
                <input
                    id="preferredLanguages"
                    formControlName="preferredLanguages"
                    class="form-control"
                    placeholder="Enter Preferred Languages"
                />
                <div *ngIf="plannerForm.get('preferredLanguages')?.invalid && plannerForm.get('preferredLanguages')?.touched">
                    <small class="text-danger">Preferred Languages is required</small>
                </div>
            </div>
            <div class="form-group mb-2">
                <label for="location" class="mb-2">Location / Address</label>
                <input
                    id="location"
                    formControlName="location"
                    class="form-control"
                    placeholder="Enter Location / Address"
                />
            </div>

            <hr />
            <div class="mt-3 d-flex justify-content-end">
                <button
                    type="button"
                    class="btn brand-btn btn-success"
                    (click)="savePlanner()"
                    [disabled]="plannerForm.invalid"
                >
                    Save
                </button>
                <button
                    type="button"
                    class="btn brand-btn bg-red1 text-white ms-2"
                    (click)="close()"
                >
                    Close
                </button>
            </div>
        </form>
    </div>
</div>
