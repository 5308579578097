import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {
  editItemForm: FormGroup;
  itemId!: string;
  inventoryItems: any; 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public apiHelper: ApiHelper
  ) {
    this.editItemForm = this.fb.group({
      sku: [''],
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      specification: ['', Validators.required],
      finish: ['', Validators.required],
      colour: ['', Validators.required],
      quantity: ['', [Validators.required, Validators.min(0)]],
      blockedQty: [''],
      rate: ['', [Validators.required, Validators.min(0)]],
      msq: ['', [Validators.required, Validators.min(0)]],
      moq: ['', [Validators.required, Validators.min(0)]],
      vendor1: [''],
      vendor2: [''],
      days: ['', [Validators.required, Validators.min(0)]],
    });
  }

  ngOnInit(): void {
    this.itemId = this.route.snapshot.paramMap.get('id')!;
    this.loadItemDetails(this.itemId);
  }

  loadItemDetails(id: string): void {
    this.apiHelper.post({ id: id }, ApiEndPoints.getItemsDetails).subscribe((response) => {
      this.inventoryItems = response.data;
      this.setFormValues(this.inventoryItems);
    });
  }

  setFormValues(item: any): void {
    this.editItemForm.patchValue({
      sku: item.sku,
      category: item.c1,
      subCategory: item.c2,
      specification: item.c3,
      finish: item.finish,
      colour: item.colour,
      quantity: item.quantity,
      blockedQty: item.blockedQty,
      rate: item.rate,
      msq: item.minimumQty,
      moq: item.minimumOrderQty,
      vendor1: item.vendor1,
      vendor2: item.vendor2,
      days: item.days,
    });
  }

  onSubmit(): void {
    if (this.editItemForm.valid) {
        const updatedItemDetails = this.editItemForm.value; 
        this.apiHelper.post({ id: this.itemId, ...updatedItemDetails }, ApiEndPoints.itemDetailsUpdate).subscribe(
            (response) => {
                this.toastr.success('Item updated successfully');
                this.router.navigate(['/inventory']); 
            },
            (error) => {
                console.error('Error updating item:', error);
                this.toastr.error('An error occurred while updating the item. Please try again.');
            }
        );
    } else {
        this.toastr.error('Please fill out all required fields correctly before submitting.');
        this.markFormFieldsTouched();
    }
  }

  markFormFieldsTouched(): void {
    Object.keys(this.editItemForm.controls).forEach(field => {
      const control = this.editItemForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
}
