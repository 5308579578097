<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template with a checkbox -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <mat-checkbox [checked]="node.checked" (change)="toggleCheckbox(node)">
        {{node.name}}
      </mat-checkbox>
    </mat-tree-node>
  
    <!-- This is the expandable tree node template with a checkbox -->
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon>
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="node.checked" (change)="toggleCheckbox(node)">
          {{node.name}}
        </mat-checkbox>
      </div>
    </mat-tree-node>
  </mat-tree>
  