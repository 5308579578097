<div [ngClass]="{ 'dark-theme': darkLight.currentTheme === 'dark' }">
  <!--sidebar-->
  <div class="sidebar bg-app-primary h-100 position-fixed container-fluid" [ngClass]="{ 'side-nav-close': sidePanel }">
    <div class="border-bottom">
      <div class="logo p-2 d-flex justif-content-center w-100 align-items-center p-4 pb-3 pt-3" [routerLink]="[]">
        <img src="assets/images/projectflow-logo.png" *ngIf="!sidePanel" class="img-fluid" alt="logo" />
        <p class="h6 text-white2" *ngIf="sidePanel">P</p>
      </div>
    </div>

    <div class="side-menu text-nowrap pb-3 mt-3 scrollbar" data-bs-spy="scroll" data-bs-target="#navbar-example">


      <div *ngFor="let item of navItems">

        <p class="sub-name h6 text-app-muted fw-600 pb-3 m-0 pt-0" *ngIf="!sidePanel"> {{ item.name }} </p>

        <div class="mb-2" *ngFor="let subItem of item.subCat">
          <div class="side-main-menu">

            <a [routerLink]="subItem.url" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              class="text-white2 item d-flex align-items-center p-4 pt-1 pb-1"
              (click)="toggleSubOptions(subItem, $event)">
              <!-- Icon for showing/hiding sub-options -->
              <i class="text-white2 {{ subItem.icon }}"></i>

              <!-- Subname text -->
              <span class="ps-2 text-white2 fw-400" *ngIf="!sidePanel">{{subItem.subname}}</span>

              <!-- Chevron icon for toggling -->
              <i class="fa fa-chevron-down ms-auto" *ngIf="subItem.subOptions && subItem.showSubOptions"></i>
              <i class="fa fa-chevron-right ms-auto" *ngIf="subItem.subOptions && !subItem.showSubOptions"></i>
            </a>

            <!-- Sub-options rendering -->
            <ul class="side-sub-menu ms-4" *ngIf="subItem.showSubOptions">
              <li class="text-white2" *ngFor="let subOption of subItem.subOptions">
                <a routerLink="{{ subOption.url }}" routerLinkActive="active"
                  class="text-white2 fw-400 d-flex align-items-center p-2">
                  <span>{{ subOption.subname }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--main-->
  <div class="wrapper d-flex flex-column min-vh-100 sidebar-content sidebar-open"
    [ngClass]="{ 'side-nav-close': sidePanel }">
    <!--app-header-->
    <div class="d-print-none bg-white header header-sticky p-0 shadow-sm">
      <div class="container-fluid">
        <app-header (closeOpenoutput)="openClose()"> </app-header>
      </div>
    </div>
    <!--app-body-->
    <div class="main-body bg-app-hash flex-grow-1 pt-3">
      <div class="h-auto container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="d-print-none bg-white p-0 border-top footer">
      <div class="container-fluid">
        <app-footer></app-footer>
      </div>
    </div>

    <!--app footer-->
  </div>
</div>