import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-component-supplier',
  templateUrl: './component-supplier.component.html',
  styleUrls: ['./component-supplier.component.scss'],
})
export class ComponentSupplierComponent {
  plannerForm!: FormGroup;
  createComponentForm!: FormGroup;

  clients = []; // Replace with your client data
  builders = []; // Replace with your architect/builder data
  contractor = []; // Replace with your contractor data
  consultant = [];
  filteredOptions: any[] = [];
  nameOptions = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    { id: 3, name: 'Alice Johnson' },
  ];
  allComponents: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiHelper: ApiHelper,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.plannerForm = this.fb.group({
      name: ['', Validators.required],
      selectedComponent: [null],
      suppliers: this.fb.array([]), // Initialize suppliers array
    });
    this.createComponentForm = this.fb.group({
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      specification: ['', Validators.required],
      finish: [null],
      color: [null],
    });

    // Add initial supplier entry
    this.addSupplier();
    this.loadAllComponents();
  }

  // Getter for suppliers FormArray
  get suppliers(): FormArray {
    return this.plannerForm.get('suppliers') as FormArray;
  }

  // Add a new supplier form group
  addSupplier(): void {
    const supplierGroup = this.fb.group({
      name: ['', Validators.required],
      moq: ['', Validators.required],
      rate: ['', Validators.required],
      days: ['', Validators.required],
    });
    this.suppliers.push(supplierGroup);
  }

  // Remove a supplier form group
  removeSupplier(index: number): void {
    this.suppliers.removeAt(index);
  }

  // Handle save logic

  
    
  

  // Handle close logic
  close(): void {
    this.router.navigate(['/componentsupplierView']);
    // Implement close or reset logic if needed
  }

  onAddTag(newName: string) {
    console.log(newName);
    const newOption = { id: this.nameOptions.length + 1, name: newName };
    this.nameOptions.push(newOption); // Add to options list
    this.plannerForm.get('name')?.setValue(newName); // Set selected value
  
  }
  loadAllComponents() {
    this.apiHelper.post({}, ApiEndPoints.getAllComponentsList).subscribe(
      (response) => {
        if (response.data) {
          this.allComponents = response.data;
          this.filteredOptions = [...this.allComponents];
          // Extract segments from the component data
        } else {
          this.toastr.error('Failed to load components.');
        }
      },
      () => {
        this.toastr.error('Error occurred while fetching components.');
      }
    );
  }
//   onSearchChange(event:any) {
//     let searchTerm = event.term
//     this.filteredOptions = [...this.allComponents];
//     if (!searchTerm) {
    
//       this.filteredOptions = [...this.allComponents];
//     } 
//     searchTerm = searchTerm.toLowerCase(); 

//       this.filteredOptions = this.allComponents.sort((a, b) => this.customSortFn(searchTerm, a.name, b.name)) .filter(item => this.customFilterFn(searchTerm, item.name));  
//   }
  
//  customSortFn(searchTerm: string, a: string, b: string): number {
//     const aStartsWith = a.toLowerCase().startsWith(searchTerm);
//     const bStartsWith = b.toLowerCase().startsWith(searchTerm);

//     if (aStartsWith && !bStartsWith) {
//       return -1;  // 'a' comes first
//     }
//     if (!aStartsWith && bStartsWith) {
//       return 1;  // 'b' comes first
//     }
//     return a.localeCompare(b);  // If neither starts with the term, sort alphabetically
//   }

//   customFilterFn(searchTerm: string, item: string): boolean {
//     return item.toLowerCase().includes(searchTerm);  // Filter based on inclusion
//   }
//   onClear(): void {
//     console.log('clear',this.allComponents)
//     this.filteredOptions = [...this.allComponents];

//   }
onChangeV1(selectedItem: any) {
  if (selectedItem) {
  
    const selectedComponent = this.allComponents.find(
      (component) => component.name === selectedItem.name
    );

    if (selectedComponent) {
      this.plannerForm.patchValue({
        selectedComponent,
        name: selectedComponent.name,
      });
    } else {
      console.error('Selected item not found in allComponents.');
    }
  }
}
onSearchChange(event: any) {
  let searchTerm = event.term;
  if (!searchTerm) {

          this.filteredOptions = [...this.allComponents];
        } 
  // console.log('search term',searchTerm)
  // console.log('all components',this.allComponents)
  // this.filteredOptions = [...this.allComponents];
  // if (searchTerm) {
    // this.filteredOptions = [...this.allComponents];
    searchTerm = searchTerm.toLowerCase();

    this.filteredOptions = this.allComponents
      .sort((a, b) => this.customSortFn(searchTerm, a.name, b.name))
      .filter(item => this.customFilterFn(searchTerm, item.name));
  }


customSortFn(searchTerm: string, a: string, b: string): number {
  const aStartsWith = a.toLowerCase().startsWith(searchTerm);
  const bStartsWith = b.toLowerCase().startsWith(searchTerm);

  if (aStartsWith && !bStartsWith) {
    return -1;  // 'a' comes first
  }
  if (!aStartsWith && bStartsWith) {
    return 1;  // 'b' comes first
  }
  return a.localeCompare(b);  
}

customFilterFn(searchTerm: string, item: string): boolean {
  return item.toLowerCase().includes(searchTerm); 
}

onClear(): void {

  this.filteredOptions = [...this.allComponents];
  this.filteredOptions = this.filteredOptions.sort((a, b) => this.customSortFn('', a.name, b.name));

}


  
  // onChange(selectedItem: any) {
  //   if (typeof selectedItem === 'string') {
  //     console.log('New Custom Value:', selectedItem);

  //     // Check if the new value already exists in the options list
  //     const exists = this.nameOptions.some(
  //       (option) => option.name === selectedItem
  //     );
  //     if (!exists) {
  //       // Create a new option
  //       const newOption = {
  //         id: this.nameOptions.length + 1,
  //         name: selectedItem,
  //       };

  //       // Add the new option to the list
  //       this.nameOptions.push(newOption);

  //       // Set the new option's ID as the selected value in the form
  //       this.plannerForm.get('name')?.setValue(selectedItem);
  //     }
      
  //   }}

  addNewComponent() {
    if (this.createComponentForm.valid) {
      const formData = this.createComponentForm.value;
  

      this.apiHelper.post(formData, ApiEndPoints.addNewComponent).subscribe(
        (response) => {
          this.toastr.success('Component created successfully!');
          this.createComponentForm.reset();
          this.loadAllComponents();
          
        },
        (error) => {
          this.toastr.error('Failed to create the component.');
          console.error('Error:', error);
        }
      );
    } else {
      this.toastr.error('Please fill out all required fields.');
    }
  }
  // savePlanner(): void {
  //   if (this.plannerForm.valid) {

  //       const plannerData = this.plannerForm.value;
  //       console.log('hi',this.plannerForm.value);
  //       this.apiHelper.post(plannerData, ApiEndPoints.createComponentSupplier).subscribe(
  //         (response) => {
  //           this.toastr.success('Component supplier saved successfully!');
            
  //           this.plannerForm.reset();
  //         },
  //         (error) => {
  //           this.toastr.error('Failed to save component supplier.');
  //           console.error('Error:', error);
  //         }
  //       );
  //     } else {
  //       this.toastr.error('Please fill out all required fields.');
  //     }
  //   }
  
  savePlanner(): void {
    if (this.plannerForm.valid) {
      const plannerData = this.plannerForm.value;
      const supplierCount = plannerData.suppliers ? plannerData.suppliers.length : 0;
      plannerData.selectedComponent.supplierCount = supplierCount;
      this.apiHelper.post(plannerData, ApiEndPoints.createComponentSupplier).subscribe(
        (response) => {
          this.toastr.success(`saved successfully! `);
          this.plannerForm.reset();
        },
        (error) => {
          if (error.error && error.error.message) {
            this.toastr.error(error.error.message);
          } else {
            this.toastr.error('Failed to save component supplier.'); 
          }
          console.error('Error:', error);
        }
      );
    } else {
      this.toastr.error('Please fill out all required fields.');
    }
  }}
