import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Input()   itemsPerPage: number = 10;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  getPaginationPages(): number[] {
    const range = 1;
    const pages: number[] = [];
    pages.push(1);
    if (this.currentPage > range + 2) {
      pages.push(-1);
    }
    for (let i = Math.max(2, this.currentPage - range); i <= Math.min(this.totalPages - 1, this.currentPage + range); i++) {
      pages.push(i);
    }
    if (this.currentPage < this.totalPages - range - 1) {
      pages.push(-1);
    }
    if (this.totalPages > 1) {
      pages.push(this.totalPages);
    }
    return pages;
  }

  changePage(page: number): void {
    if (page >= 1 && page <= this.totalPages && page !== this.currentPage) {
      this.pageChange.emit(page);
    }
  }
}
