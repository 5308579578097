export enum ApiEndPoints {
    signIn = 'auth/login',
    signUp = 'auth/signUp',
    profileGet = 'profile/get',
    profileFind = 'profile/find',
    profileUpdate='profile/update',
    search ='product/getAll',

    // Project 

    projectCreate =  "project/create",
    projectUpdate =  "project/update",
    getProjectDetails =  "project/get",
    allProjects = "project/find",


    // Qoutes 
    quoteCreate =  "quote/create",
    quoteUpdate =  "quote/update",
    getQuoteDetails =  "quote/get",
    allquotes = "quote/findAll",
    getQuoteNumber ="quote/getQuoteNumber",
    getJobData = "quote/findJob",
    

    // Qoutes-List
    quoteListCreate =  "quoteList/create",
    quoteListUpdate =  "quoteList/update",
    getQuoteListDetails =  "quoteList/get",
    allquoteLists = "quoteList/find",
    quoteItemDelete = "quoteList/delete",


    // Inventory 

    getAllItems = "stock/find",
    getItemsDetails = "stock/get",
    itemDetailsUpdate = "stock/update",


    // Items 
    saveItemComponent = "item/createComponent",
    getItemComponent = "item/getComponent",


    // Order

    createOrder = "order/create",
    getPurchases = "order/find",
    getPurchaseDetails = "order/get",
    addItems = "order/addItems",
    updateOrder = "order/update",



    // Suppliers

    getAllSuppliers = "supplier/find",


    // Audit 

    auditData = "audit/find",


    // Component Assembly 


    getAllComponents = "assembly/getAll",
    addItemComponent = "assembly/addItemComponent",


    // Work Plan 


    createWorkPlan = "workPlan/create",


//  Client Details 

getAllClients = "client/find",
createClient  = "client/create",
getClientDetails = "client/get",
clientUpdate="client/update",


// Construction Company Details 

getAllBuilders = "construction/find",
createPlanner = "construction/create",
getBuilderDetails = "construction/get",
updateBuilder = "construction/update",

// Companies


getAllCompanies = "company/find",

// Review
revisionCreate = "revision/create",
reviewDetailsGet = "revision/get",
reviewDetailsGetV2 = "revision/getV2",
reviewUpdate = "revision/update",
getRevisionJob = "revision/findJob",

// ReviewList
revisionListCreate = "revisionList/create",
revisionListGet = "revisionList/get",
revisionListCreateBySearch = "revisionList/create/search",
revisionListUpdate = "revisionList/update",
revisionItemDelete = "revisionList/delete",

// Region
getAllRegions = "region/find",


// Job
createJobFromQuote = "job/create/quotes",
createJobFromRevision = "job/create/revision",
getJobDetails = "job/get",
getAllJob ="job/find",



// JobItems 

createJobItems = "jobItems/create",


 
}