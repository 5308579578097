<div class="d-flex justify-content-between mb-2 align-items-center">
    <h5 class = " text-uppercase font-weight-bold m-0">Contractors</h5>
    <button type="button" class="btn btn-primary" (click)="openPlannerCreate()">
        <i class="fa fa-plus me-1"></i> Add Contractor
      </button>
</div>

<div class="col-md-2 col-sm-12 search mb-3">
    <input 
      type="text" 
      class="form-control" 
      placeholder="Search by name" 
      [(ngModel)]="searchTerm" 
      (input)="onSearch()"
    />
  </div>


  <div class="card-wrap bg-white">
    <div class="table-responsive">
  <table class="table table-hover table-border table-common">
    <thead class="thead-light">
      <tr>
        <th>Name</th>
        <th>Phone Number</th>
        <th>Preferred Languages </th>
        <th>Location </th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let planner of paginatedPlanners">
        <td>{{ planner.name }}</td>
        <td>{{ planner.phoneNumber }}</td>
        <td>{{ planner.preferredLanguages }}</td>
        <td>{{ planner.location }}</td>
        <td>
          <a [routerLink]="['/edit-builder', planner.id,'contractors']" class="ms-2">
          <i class="fa fa-edit text-primary"></i>
        </a>
      </td> 

      </tr>
    </tbody>
    
  </table>
  </div>
  </div>

  <!-- Pagination Controls -->

  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="onPageChange(currentPage - 1)">Previous</a>
      </li>

      <li 
        class="page-item" 
        *ngFor="let page of [].constructor(totalPages); let i = index" 
        [class.active]="currentPage === i + 1"
      >
        <a class="page-link" (click)="onPageChange(i + 1)">{{ i + 1 }}</a>
      </li>

      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="onPageChange(currentPage + 1)">Next</a>
      </li>
    </ul>
  </nav>
