<div class="container h-100">
  <div class="row p-2 h-100">
    <!-- Main Content Area: Search and Results -->
    <div class="col-md-7 col-sm-12">
      <div class="card h-100">
        <div class="card-body">
          <h5 class="mb-3">Component Search</h5>
          <hr />
          <form [formGroup]="addComponentForm" class="mb-2">
            <!-- Dropdown for selecting segment -->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <h6 class="pb-2">Select Segment</h6>
                  <!-- <select
                    id="selectSegment"
                    formControlName="selectedSegment"
                    class="form-control"
                  >
                
                    <option *ngFor="let segment of segmentOptions" [value]="segment">
                      {{ segment }}
                    </option>
                  </select> -->
                  <ng-select
                  [items]="filteredSegmentOptions"
                  bindLabel="segment"
                  placeholder="Search Product Segment"
                  formControlName="selectedSegment"
                  [searchable]="true"
                  (search)="onSearchChangeSegment($event)"
                  (clear)="onClearSelection()"
                 
                >
                </ng-select>
                  <!-- <ng-select
                    [items]="filteredSegmentOptions"
                    bindLabel="segment"
                    [searchable]="true"
                    formControlName="selectedSegment"
                    placeholder="Select a segment"
                    class="form-control"
                    (search)="onSearchChangeSegment($event)"
                  >
                  </ng-select> -->
                </div>
              </div>

              <!-- Dropdown for selecting componentType (shown only when segment is selected) -->
              <div
                class="form-group col-md-6"
                *ngIf="addComponentForm.get('selectedSegment')?.value"
              >
                <h6 class="pb-2">Select Component Type</h6>
                <!-- <select
                  id="selectComponentType"
                  formControlName="selectedComponentType"
                  class="form-control"
                >
                <option value="" style="display : none;"> </option>
                  <option *ngFor="let componentType of filteredComponentTypeOptions" [value]="componentType">
                    {{ componentType }}
                  </option>
                </select> -->
                <ng-select
                  [items]="filteredOptions"
                  bindLabel="type"
                  placeholder="Search Component Type"
                  formControlName="selectedComponentType"
                  [searchable]="true"
                  (search)="onSearchChange($event)"
                  (clear)=" onClear()"
                >
                </ng-select>
              </div>
            </div>
          </form>

          <!-- Search Results Section -->
          <div
            class="scrollbar"
            *ngIf="
              addComponentForm.get('selectedComponentType')?.value;
              else noResults
            "
          >
            <div
              *ngFor="let component of filteredComponents"
              class="d-flex align-items-center mb-1 p-2 border rounded"
            >
              <span class="flex-grow-1">{{ component.c3 }}</span>
              <span class="flex-grow-1">{{ component.finish }}</span>
              <span class="flex-grow-1">{{ component.colour }}</span>
              <button
                (click)="addComponentFromList(component)"
                class="btn btn-primary btn-sm"
              >
                Add Component
              </button>
            </div>
          </div>

          <!-- No Results Template -->
          <ng-template #noResults>
            <div *ngIf="filteredComponents.length === 0" class="text-center">
              <img
                src="./assets/images/no-data2.png"
                class="img-fluid noData2"
                alt="no data file"
              />
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- Sidebar for Selected Components -->
    <div class="col-md-5 col-sm-12">
      <div class="card">
        <div class="card-body">
          <h5 class="mb-3">Selected Components</h5>
          <hr />
          <div class="scrollbar">
            <!-- Iterate over newItems (which are of type Item) -->
            <div *ngFor="let item of newItems; let i = index" class="mb-2">
              <div
                class="d-flex justify-content-between w-100 align-items-center"
              >
                <h6 class="mb-0 h7 text-capitalize ellipsis">
                  {{ item.c1 }}, {{ item.c2 }}. {{ item.c3 }}
                </h6>
                <div class="d-flex align-items-center">
                  <!-- Quantity Input Field for Item -->
                  <input
                    type="number"
                    class="form-control me-2"
                    [(ngModel)]="item.qty"
                    min="1"
                    placeholder="Qty"
                    (input)="onInputChange($event, item)"
                  />
                  <button
                    class="btn btn-primary btn-sm me-2"
                    [ngClass]="{
                      'btn-success': !item.isQtyChanged,  
                      'btn-warning': item.isQtyChanged
                    }"
                    (click)="updateComponentQuantity(item, item.qty)"
                  >
                    Update
                  </button>
                  <i
                    class="fa fa-trash text-red1 p-2"
                    (click)="removeComponent(item)"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>

            <!-- No data found message -->
            <div *ngIf="newItems.length <= 0">
              <img
                src="./assets/images/no-data.png"
                class="img-fluid"
                alt="no data file"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end w-100 mt-3">
        <button
          class="btn btn-success"
          *ngIf="newItems.length > 0"
          (click)="closeDialog()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
