import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { PaginationService } from 'src/app/core/service/pagination.service';

declare var bootstrap: any;

// interface ProjectData {
//   id: number;
//   name: string;
//   clientInfo: {
//     name: string;
//     phone: string;
//     location: string;
//   };
//   planner: {
//     name: string;
//     phone: string;
//     type: string;
//     location: string;
//   };
//   location: string;
//   isActive: string;
//   createdAt?: Date;
// }

@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {
  createProjectForm: FormGroup;
  projectData: any[] = [];
  filteredProjectData: any[] = [];
  clients: any[] = [];
  builders: any[] = [];
  searchTerm: string = '';
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalPages: number = 1;

  constructor(
    public apiHelper: ApiHelper,
    private router: Router,
    public toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.createProjectForm = this.fb.group({
      name: ['', Validators.required],
      clientId: ['', Validators.required],
      plannerId: [''],
      location: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getProjects();
    this.getAllClients();
    this.getAllBuilders();
  }

  getProjects(): void {
    this.apiHelper.post({}, ApiEndPoints.allProjects).subscribe((response) => {
      if (response && response.data) {
        this.projectData = response.data;
        this.filterProjects();
      } else {
        this.toastr.error('Failed to load projects.');
      }
    });
  }

  getAllClients(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllClients).subscribe((response) => {
      if (response && response.data) {
        this.clients = response.data;
      } else {
        this.toastr.error('Failed to load clients.');
      }
    });
  }

  getAllBuilders(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllBuilders).subscribe((response) => {
      if (response && response.data) {
        this.builders = response.data;
      } else {
        this.toastr.error('Failed to load builders.');
      }
    });
  }
  
  showData(id: string): void {
    this.router.navigate(['/project-details', id]);
  }

  submitProject(): void {
    if (this.createProjectForm.valid) {
      const newProject = this.createProjectForm.value;
      console.log(newProject)
      this.apiHelper.post(newProject, ApiEndPoints.projectCreate).subscribe((response) => {
        if (response && response.data) {
          console.log("responseData",response.data)
          this.toastr.success('Project created successfully!');
          this.projectData.push({
            ...newProject,
            clientInfo: this.clients.find(client => client.id === newProject.clientId),
            builderInfo: this.builders.find(builder => builder.id === newProject.builderId),
            isActive: 'active',
            createdAt: new Date(),
          });
          console.log("projectData",this.projectData)
          this.filterProjects(); // Re-filter with the new project added
          this.closeModal();
          this.createProjectForm.reset();
        } else {
          this.toastr.error('Failed to create project.');
        }
      });
    } else {
      this.toastr.warning('Please fill in all the required fields.');
    }
  }

  submitProjectV2(): void {
    if (this.createProjectForm.valid) {
      const newProject = this.createProjectForm.value;
      this.apiHelper.post(newProject, ApiEndPoints.projectCreate).subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Project created successfully!');
          this.projectData.push(response.data);
          console.log(this.projectData)
          this.filterProjects(); // Re-filter with the new project added
          this.closeModal();
          this.createProjectForm.reset();
        } else {
          this.toastr.error('Failed to create project.');
        }
      });
    } else {
      this.toastr.warning('Please fill in all the required fields.');
    }
  }

  closeModal(): void {
    const modalElement = document.getElementById('createProjectModal');
    if (modalElement) {
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    }
  }

  searchProjects(): void {
    this.filterProjects();
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.filterProjects();
  }

  filterProjects(): void {
    let filtered = [...this.projectData];
    if (this.searchTerm.trim()) {
      filtered = filtered.filter(item =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }

    this.totalPages = Math.ceil(filtered.length / this.itemsPerPage);
    this.filteredProjectData = filtered.slice(
      (this.currentPage - 1) * this.itemsPerPage,
      this.currentPage * this.itemsPerPage
    );
  }
}
