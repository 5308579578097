<div class="card-wrap bg-white">
  <div class="table-responsive">
    <table class="table table-hover table-border table-common">
      <thead class="thead-light">
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Project Id</th>
          <th scope="col">Created At</th>
          <th scope="col">Company</th>
          <th scope="col">View</th>
        </tr>
      </thead>
      <tbody>
        <!-- Iterate over paginated data 'jsonb' instead of 'quotesData' -->
        <tr *ngFor="let quote of jsonb" (click)="showData(quote.id)" style="cursor: pointer;">
          <td>{{ quote.name }}</td>
          <td>{{ quote.projectId }}</td>
          <td>{{ quote.createdAt | date: 'dd,MMM yyyy' }}</td>
          <td>{{ quote.company?.id }}</td>
          <td>
            <a (click)="$event.stopPropagation(); showData(quote.id)"> 
              <i class="fa fa-eye text-color-secondary" aria-hidden="true"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-pagination 
    [currentPage]="currentPage" 
    [totalPages]="totalPages" 
    [itemsPerPage]="itemsPerPage" 
    (pageChange)="onPageChange($event)">
  </app-pagination>
</div>
