import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-cost-modal',
  templateUrl: './cost-view.component.html',
  styleUrls: ['./cost-view.component.scss'],
})
export class CostModalComponent implements OnInit {
  costForm!: FormGroup;
  calculatedExpense: number = 0;
  totalCost: number = 0;
  gstCost: number = 0;
  subtotal: number = 0;
  item: any;

  constructor(
    public dialogRef: MatDialogRef<CostModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.item = data.item;
  }

  ngOnInit(): void {
    this.costForm = this.fb.group({
      totalMaterialCost: [
        this.data.totalMaterialCost || 0,
        [Validators.required, Validators.min(0)],
      ],
      glassCost: [
        this.data.totalGlassCost || 0,
        [Validators.required, Validators.min(0)],
      ],
      labourCost: [
        this.data.totalLaborCost || 0,
        [Validators.required, Validators.min(0)],
      ],
      expensePercentageAH: [
        this.data.aOhFactor || 0,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      expensePercentageAE: [
        this.data.profitFactor || 0,
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
    });

    this.calculateTotalCost();
    this.costForm.valueChanges.subscribe(() => {
      this.calculateTotalCost();
    });
  }

  calculateTotalCost() {
    const values = this.costForm.value;
    const material = values.totalMaterialCost || 0;
    const glass = values.glassCost || 0;
    const labour = values.labourCost || 0;

    const ahExpense =
      ((material + glass + labour) * values.expensePercentageAH) / 100;
    const aeExpense =
      ((material + glass + labour) * values.expensePercentageAE) / 100;

    this.calculatedExpense = ahExpense + aeExpense;
    this.subtotal = material + glass + labour + this.calculatedExpense;
    this.gstCost = (this.subtotal * 18) / 100;
    this.totalCost = this.subtotal + this.gstCost;
  }

  saveCosts() {
    this.dialogRef.close({
      totalCost: this.totalCost,
      itemId: this.item.id,
      totalMaterialCost: this.costForm.value.totalMaterialCost,
      totalLaborCost: this.costForm.value.labourCost,
      totalGlassCost: this.costForm.value.glassCost,
      aOhFactor: this.costForm.value.expensePercentageAH,
      profitFactor: this.costForm.value.expensePercentageAE,
      gstCost: this.gstCost,
      subTotal: this.subtotal,
      calculatedExpense: this.calculatedExpense,
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
