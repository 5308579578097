<div *ngIf="workPlanDetails">
  <h3>Work Plan Details</h3>
  <div class="card-wrap bg-white">
    <div class="table-responsive">
      <table class="table table-hover table-border table-common">
        <thead class="thead-light">
          <tr>
            <th>S.No</th>
            <th>Work Plan Item ID</th> 
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of workPlanDetails ; let i=index">
            <td>{{i+1}}</td>
            <td>{{ item.id || "N/A" }}</td>

            <td>{{ item.quantity || "N/A" }}</td>
         
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #noWorkPlanDetails>
  <div class="alert alert-warning">
    No work plan details found for this work plan.
  </div>
</ng-template>
