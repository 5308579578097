<form [formGroup]="editBuilderForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="name" class="form-label">Name</label>
      <input type="text" id="name" formControlName="name" class="form-control" required>
    </div>
    <div class="mb-3">
      <label for="phoneNumber" class="form-label">Phone Number</label>
      <input type="text" id="phonenumber" formControlName="phoneNumber" class="form-control" required>
    </div>
    <div class="mb-3">
      <label for="preferredLanguages" class="form-label">Preferred Languages</label>
      <input type="text" id="preferredLanguages" formControlName="preferredLanguages" class="form-control" required>
    </div>
    <div class="mb-3">
      <label for="location" class="form-label">Location</label>
      <input type="text" id="location" formControlName="location" class="form-control" required>
    </div>
<div class="modal-footer">
  <button type="submit" class="me-2 brand-btn bg-dark-green border-0 text-white">Save Changes</button>
  <a [routerLink]="['/planners']" class="btn btn-secondary">Cancel</a>
</div>
</form>