<div class="card">
  <div class="card-body">
    <h5>Create Work Plan</h5>
    <hr />
    <form [formGroup]="workPlanForm">
      <div class="form-group mb-2">
        <label for="assignedTo" class="mb-2">Assigned To</label>
        <input
          id="assignedTo"
          formControlName="assignedTo"
          class="form-control"
          placeholder="Enter person responsible"
        />
        <div
          *ngIf="
            workPlanForm.get('assignedTo')?.invalid &&
            workPlanForm.get('assignedTo')?.touched
          "
        >
          <small class="text-danger">This field is required</small>
        </div>
      </div>

      <h6 classs="pt-2">Items</h6>
      <div formArrayName="items">
        <div
          *ngFor="let item of items.controls; let i = index"
          [formGroupName]="i"
          class="form-group"
        >
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <label for="itemId">Item</label>
              <select id="itemId" formControlName="itemId" class="form-control">
                <option value="">Select an item</option>
                <option
                  *ngFor="let quoteItem of itemsFromQuote"
                  [value]="quoteItem.id"
                >
                  {{ quoteItem.productInfo?.name || "Unknown Product" }} (Qty:
                  {{ quoteItem.qty }})
                </option>
              </select>
            </div>
            <div class="col-md-5 col-sm-12">
              <label for="quantity">Quantity</label>
              <input
                id="quantity"
                formControlName="quantity"
                type="number"
                class="form-control"
                [max]="getItemMaxQuantity(i)"
                placeholder="Enter quantity"
              />
            </div>
            <div class="col-md-1 col-sm-12 d-flex align-items-center">
              <i
                class="fa fa-trash text-red1 bg-red10 p-2 mt-4"
                (click)="removeItem(i)"
                aria-hidden="true"
              ></i>
            </div>
          </div>

          <small
            class="text-danger"
            *ngIf="
              items.controls[i].get('quantity')?.value > getItemMaxQuantity(i)
            "
          >
            Quantity cannot be greater than available.
          </small>
        </div>
      </div>

      <button
        type="button"
        class="btn brand-btn btn-primary mt-2"
        (click)="addItem()"
      >
        Add Another Item
      </button>
      <hr />
      <div class="mt-3 d-flex justify-content-end">
        <button
          type="button"
          class="btn brand-btn btn-success"
          (click)="saveWorkPlan()"
          [disabled]="workPlanForm.invalid"
        >
          Save Work Plan
        </button>
        <button
          type="button"
          class="btn brand-btn bg-red1 text-white ms-2"
          (click)="close()"
        >
          Close
        </button>
      </div>
    </form>
  </div>
</div>
