import { Component } from '@angular/core';

@Component({
  selector: 'app-view-work-plans',
  templateUrl: './view-work-plans.component.html',
  styleUrls: ['./view-work-plans.component.scss']
})
export class ViewWorkPlansComponent {

  items : any[]=[];

  isObject(value: any): boolean {
    return typeof value === 'object';
  }


}
