import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

interface Purchase {
  id: string;
  supplierId: string;
  quantity: string;
  expectedDate: string;
  status: string;
  rate: string;
}

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
})
export class PurchaseComponent implements OnInit {
  purchases: Purchase[] = [];
  filteredPurchases: Purchase[] = [];
  uniqueStatuses: string[] = [];
  uniqueDates: string[] = [];

  constructor(public apiHelper: ApiHelper, private router: Router) {}

  ngOnInit(): void {
    this.getPurchases(); 
  }

  filterByDate(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const selectedDate = target.value;

    if (!selectedDate) {
      
      this.filteredPurchases = [...this.purchases];
    } else {
     
      this.filteredPurchases = this.purchases.filter(
        (purchase) => purchase.expectedDate === selectedDate
      );
    }
  }

  filterByStatus(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const selectedStatus = target.value;

    if (!selectedStatus) {
      this.filteredPurchases = [...this.purchases];
    } else {
      this.filteredPurchases = this.purchases.filter(
        (purchase) => purchase.status === selectedStatus
      );
    }
  }

  viewPurchase(purchaseId: string): void {
    this.router.navigate(['/purchase-details', purchaseId]);
  }

  getPurchases(): void {
    this.apiHelper.post({}, ApiEndPoints.getPurchases).subscribe(
      (response) => {
        if (response && response.data) {
          this.purchases = response.data;
          this.filteredPurchases = [...this.purchases];
          this.extractUniqueValues();
        }
      },
      (error) => {
        console.error('Error fetching purchase details', error);
      }
    );
  }

  
  extractUniqueValues(): void {
    const statuses = this.purchases.map((purchase) => purchase.status);
    const dates = this.purchases.map((purchase) => purchase.expectedDate);

    this.uniqueStatuses = [...new Set(statuses)]; 
    this.uniqueDates = [...new Set(dates)]; 
  }
}
