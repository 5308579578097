<div class="card-wrap bg-white">
  <h5 class="text-default-text-color mb-3 text-uppercase font-weight-bold">Edit Item</h5>

  <form [formGroup]="editItemForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="sku" class="form-label">SKU</label>
          <input type="text" id="sku" formControlName="sku" class="form-control" required>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="category" class="form-label">Category</label>
          <input type="text" id="category" formControlName="category" class="form-control" required>
          <div *ngIf="editItemForm.get('category')?.invalid && editItemForm.get('category')?.touched" class="text-danger">
            Category is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="subCategory" class="form-label">Sub Category</label>
          <input type="text" id="subCategory" formControlName="subCategory" class="form-control" required>
          <div *ngIf="editItemForm.get('subCategory')?.invalid && editItemForm.get('subCategory')?.touched" class="text-danger">
            Sub Category is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="specification" class="form-label">Specification</label>
          <input type="text" id="specification" formControlName="specification" class="form-control" required>
          <div *ngIf="editItemForm.get('specification')?.invalid && editItemForm.get('specification')?.touched" class="text-danger">
            Specification is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="finish" class="form-label">Finish</label>
          <input type="text" id="finish" formControlName="finish" class="form-control" required>
          <div *ngIf="editItemForm.get('finish')?.invalid && editItemForm.get('finish')?.touched" class="text-danger">
            Finish is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="colour" class="form-label">Colour</label>
          <input type="text" id="colour" formControlName="colour" class="form-control" required>
          <div *ngIf="editItemForm.get('colour')?.invalid && editItemForm.get('colour')?.touched" class="text-danger">
            Colour is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="quantity" class="form-label">Quantity</label>
          <input type="text" id="quantity" formControlName="quantity" class="form-control" required>
          <div *ngIf="editItemForm.get('quantity')?.invalid && editItemForm.get('quantity')?.touched" class="text-danger">
            Quantity is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="blockedQty" class="form-label">Blocked Qty</label>
          <input type="text" id="blockedQty" formControlName="blockedQty" class="form-control" required>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="rate" class="form-label">Rate</label>
          <input type="text" id="rate" formControlName="rate" class="form-control" required>
          <div *ngIf="editItemForm.get('rate')?.invalid && editItemForm.get('rate')?.touched" class="text-danger">
            Rate is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="msq" class="form-label">MSQ</label>
          <input type="text" id="msq" formControlName="msq" class="form-control" required>
          <div *ngIf="editItemForm.get('msq')?.invalid && editItemForm.get('msq')?.touched" class="text-danger">
            MSQ is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="moq" class="form-label">MOQ</label>
          <input type="text" id="moq" formControlName="moq" class="form-control" required>
          <div *ngIf="editItemForm.get('moq')?.invalid && editItemForm.get('moq')?.touched" class="text-danger">
            MOQ is required.
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="vendor1" class="form-label">Vendor 1</label>
          <input type="text" id="vendor1" formControlName="vendor1" class="form-control">
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="vendor2" class="form-label">Vendor 2</label>
          <input type="text" id="vendor2" formControlName="vendor2" class="form-control">
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-3">
        <div class="mb-3">
          <label for="days" class="form-label">Days</label>
          <input type="number" id="days" formControlName="days" class="form-control" required>
          <div *ngIf="editItemForm.get('days')?.invalid && editItemForm.get('days')?.touched" class="text-danger">
            Days is required.
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="me-2 brand-btn bg-dark-green border-0 text-white">Save Changes</button>
      <a [routerLink]="['/inventory']" class="btn btn-secondary">Cancel</a>
    </div>
  </form>
</div>
