import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { SearchmodalComponent } from '../searchmodal/searchmodal.component';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { AddComponentModalComponent } from '../addcomponentmodal/addcomponentmodal.component';
import { WorkPlanCreateModalComponent } from '../../work-plan-create-modal/work-plan-create-modal.component';
import { ToastrService } from 'ngx-toastr';
import { DownloadQuotesPdfComponent } from '../download-quotes-pdf/download-quotes-pdf.component';

@Component({
  selector: 'app-quotes-view',
  templateUrl: './quotes-view.component.html',
  styleUrls: ['./quotes-view.component.scss'],
})
export class QuotesViewComponent implements OnInit {
  searchForm: FormGroup;
  projectDetails: any = null;
  selectedItem: any = null;
  quote: any = {
    items: [],
  };
  quoteId!: string;
  quoteSumbited: boolean = false;
  quoteCreated: boolean = false;
  quoteApproved: boolean = false;

  revisionId!: string;
  isQtyChanged!: boolean;
  jobId!: string;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public apiHelper: ApiHelper,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.searchForm = this.fb.group({
      searchTerm: [''],
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      this.quoteId = id || '';
      if (id) {
        this.getQuoteDetails(id);
      }
    });
  }

  isObject(value: any): boolean {
    return typeof value === 'object';
  }

  getQuoteDetails(id: string) {
    this.apiHelper
      .post({ id: id }, ApiEndPoints.getQuoteDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.quote = response.data.quoteDetails;
          this.quote.items = response.data.quoteinfoDetails;
          this.quote.items.forEach((item: { isQtyChanged: boolean }) => {
            item.isQtyChanged = false;
          });
          this.getProjectDetails(this.quote.projectId);
          if (this.quote.status === 'submitted') {
            this.quoteSumbited = true;
          }
          if (this.quote.status === 'created') {
            this.quoteCreated = true;
          }
          if (this.quote.status === 'approved') {
            this.quoteApproved = true;
          }
          if (this.quoteApproved) {
            this.apiHelper
            .post({ id: this.quote.id }, ApiEndPoints.getJobData)
            .subscribe((response: any) => {
              if (response && response.data) {
                this.jobId = response.data;
                this.router.navigate(['/job-view', this.jobId]);
              }
            });
          }
        }
      });
  }

  getProjectDetails(projectId: string) {
    this.apiHelper
      .post({ id: projectId }, ApiEndPoints.getProjectDetails)
      .subscribe((response: any) => {
        if (response && response.data) {
          this.projectDetails = response.data.projectDetails;
        }
      });
  }

  selectItem(item: any) {
    this.selectedItem = item;

    if (this.selectedItem && this.selectedItem.id) {
      const quoteListId = this.selectedItem.id;

      this.apiHelper
        .post({ quoteListId: quoteListId }, ApiEndPoints.getItemComponent)
        .subscribe(
          (response: any) => {
            if (response && response.data) {
              this.selectedItem.components = response.data;
            } else {
              this.toastr.error('Failed to fetch components.');
            }
          },
          (error) => {
            this.toastr.error('An error occurred while fetching components.');
          }
        );
    }
  }

  updateItemQuantity(item: any) {
    const updatedItem = { id: item.id, qty: item.qty };
    this.apiHelper.post(updatedItem, ApiEndPoints.quoteListUpdate).subscribe(
      (response: any) => {
        if (response && response.data) {
          this.toastr.success('Quantity updated successfully!');
          item.isQtyChanged = false;
        } else {
          this.toastr.error('Failed to update quantity.');
        }
      },
      (error) => {
        this.toastr.error('An error occurred while updating quantity.');
      }
    );
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SearchmodalComponent, {
      width: '1000px',
      height: '500px',
      data: {
        searchTerm: this.searchForm.get('searchTerm')?.value,
        selectedItems: this.quote.items,
        quoteId: this.quote.id,
        type: 'quotesView',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedItems) {
        result.selectedItems.forEach((item: any) => {
          this.quote.items.push(item);
        });
      }
    });
  }

  openPdfModal() {
    const dialogRef = this.dialog.open(DownloadQuotesPdfComponent, {
      width: '10000px',
      height: '500px',
      data: {
        quoteItems: this.quote.items,
        quoteDetails: this.quote,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Pdf Download Succefully');
      }
    });
  }

  openAddComponentDialog() {
    if (!this.selectedItem) {
      alert('Please select an item to add components.');
      return;
    }

    const dialogRef = this.dialog.open(AddComponentModalComponent, {
      width: '10000px',
      height: '500px',
      data: {
        itemId: this.selectedItem.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.selectedComponents) {
        this.selectedItem.components = this.selectedItem.components || [];
        this.selectedItem.components.push(...result.selectedComponents);

        this.apiHelper
          .post(
            {
              quoteListId: this.selectedItem.id,
              components: this.selectedItem.components,
            },
            ApiEndPoints.saveItemComponent
          )
          .subscribe(
            (response: any) => {
              if (response && response.data) {
                this.toastr.success('Component added and saved successfully!');
              } else {
                this.toastr.error('Failed to save component.');
              }
            },
            (error) => {
              this.toastr.error(
                'An error occurred while saving the component.'
              );
            }
          );
      }
    });
  }

  updateStatusSubmit(id: string) {
    const data = {
      id: id,
      status: 'submitted',
    };
    this.apiHelper
      .post(data, ApiEndPoints.quoteUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Quote is Submit to Client');
          window.location.reload();
        } else {
          this.toastr.error('Error...!');
        }
      });
  }

  createAndSaveRevision() {
    const data = {
      quoteId: this.quoteId,
    };

    this.apiHelper.post(data, ApiEndPoints.revisionCreate, true).subscribe(
      (response) => {
        if (response && response.data) {
          this.revisionId = response.data.reviewDetails.id;
          const revisionListData = {
            quoteId: this.quoteId,
            reviewListDetails: this.quote.items,
            reviewId: this.revisionId,
          };
          this.apiHelper
            .post(revisionListData, ApiEndPoints.revisionListCreate, true)
            .subscribe(
              (response) => {
                if (response && response.data) {
                  this.router.navigate(['/revision-view', this.revisionId]);
                  this.toastr.success(
                    'Revision Quotes Items added and saved successfully!'
                  );
                } else {
                  this.toastr.error('Failed to save Revision Items.');
                }
              },
              (error: any) => {
                this.toastr.error(
                  'An error occurred while saving the Revision Items.'
                );
              }
            );
        } else {
          this.toastr.error('Failed to create RevisionQuotes.');
        }
      },
      (error: any) => {
        this.toastr.error(
          'An error occurred while creating the RevisionQuotes.'
        );
      }
    );
  }

  confirmJob(id: string) {
    const data = {
      id,
      status: 'approved',
    };

    this.apiHelper
      .post(data, ApiEndPoints.quoteUpdate, true)
      .subscribe((response) => {
        if (response && response.data) {
          this.toastr.success('Job Confirmed');
          this.createJobFromQuote(id);
        }
      });
  }

  createJobFromQuote(id: string) {
    const data = { id, entityType: "quote" };
  
    this.apiHelper
      .post(data, ApiEndPoints.createJobFromQuote, true)
      .subscribe(
        (response) => {
          if (response && response.data) {
            this.jobId = response.data.id;  // Store the jobId after successful creation
            const revisionListData = {
              quoteId: this.quoteId,
              jobItems: this.quote.items,
              jobId: this.jobId,
            };
  
            // Now, create job items only after the job has been created successfully
            this.apiHelper
              .post(revisionListData, ApiEndPoints.createJobItems, true)
              .subscribe(
                (response) => {
                  if (response && response.data) {
                    this.router.navigate(['/job-view', this.jobId]);  // Navigate to the job view page
                    this.toastr.success('Job Items added and saved successfully!');
                  } else {
                    this.toastr.error('Failed to save Job Items.');
                  }
                },
                (error) => {
                  this.toastr.error('An error occurred while saving the Job Items.');
                }
              );
  
            this.toastr.success('Job Created from Quote');
            // window.location.reload(); // Reload after creating the job
          } else {
            this.toastr.error('Failed to create job from quote');
          }
        },
        (error) => {
          this.toastr.error('An error occurred while creating the job.');
        }
      );
  }
  
  previewsQuotes() {
    this.router.navigate(['/quote-revision-view', this.quoteId]);
  }

  onInputChange(event: any, item: any) {
    const originalQty = event.target.value;

    if (originalQty !== item.qty) {
      item.isQtyChanged = true;
    } else {
      item.isQtyChanged = false;
    }
  }

  deleteItem(id: string) {
    this.apiHelper
      .post({ id }, ApiEndPoints.quoteItemDelete, true)
      .subscribe(
        (response: any) => {
          if (response && response.data) {
            this.toastr.success('Item deleted successfully!');
            this.quote.items = this.quote.items.filter((item: any) => item.id !== id);
          } else {
            this.toastr.error('Failed to delete the item.');
          }
        },
        (error) => {
          this.toastr.error('An error occurred while deleting the item.');
        }
      );
  }

  confirmDelete(itemId: string) {
    if (confirm("Are you sure you want to delete this item?")) {
      this.deleteItem(itemId);
    }
  }
  // unwanted
  openWorkPlanCreate() {
    const dialogRef = this.dialog.open(WorkPlanCreateModalComponent, {
      width: '600px',
      data: {
        items: this.quote.items,
        quoteListId: this.quote.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.success('Work Plan Created!');
      }
    });
  }
}
