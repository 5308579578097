import { Component, OnInit } from '@angular/core';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpdateSupplierComponent } from '../update-supplier/update-supplier.component';

interface ComponentDetails {
  id: string;
  name: string;
}

interface Supplier {
  id: string;
  name: string;
  moq: number;
  rate: number;
  days: number;
  componentDetails: ComponentDetails | null;
}

interface GroupedComponent {
  id: string;
  name: string;
  suppliers: Supplier[];
}

@Component({
  selector: 'app-component-supplier-view',
  templateUrl: './component-supplier-view.component.html',
  styleUrls: ['./component-supplier-view.component.scss'],
})
export class ComponentSupplierViewComponent implements OnInit {
  groupedComponents: GroupedComponent[] = [];
  selectedSupplierDetails: { [key: string]: { moq: number; rate: number; days: number; supplierId: string } } = {};

  constructor(
    private apiHelper: ApiHelper,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  redirectToAddSupplier(): void {
    this.router.navigate(['/componentsupplier']);
  }

  ngOnInit(): void {
    this.loadComponentSuppliers();
  }

  loadComponentSuppliers(): void {
    this.apiHelper.post({}, ApiEndPoints.getAllComponentSuppliers).subscribe(
      (response) => {
        if (response && response.data) {
          this.groupComponents(response.data);
        } else {
          this.toastr.error('No data found in the response.');
        }
      },
      (error) => {
        this.toastr.error('Error occurred while fetching component suppliers.');
        console.error('Error:', error);
      }
    );
  }

  groupComponents(suppliers: Supplier[]): void {
    const grouped: { [key: string]: GroupedComponent } = {};

    suppliers.forEach((supplier) => {
      if (supplier.componentDetails) {
        const componentId = supplier.componentDetails.id;

        if (!grouped[componentId]) {
          grouped[componentId] = {
            id: componentId,
            name: supplier.componentDetails.name,
            suppliers: [],
          };
          this.selectedSupplierDetails[componentId] = { moq: 0, rate: 0, days: 0, supplierId: '' };
        }

        grouped[componentId].suppliers.push({
          ...supplier,
          id: supplier.id,
        });
      }
    });

    this.groupedComponents = Object.values(grouped);
  }

  onSupplierChange(event: Event, componentId: string): void {
    const supplierId = (event.target as HTMLSelectElement).value;

    const selectedComponent = this.groupedComponents.find((component) => component.id === componentId);

    if (selectedComponent) {
      const selectedSupplier = selectedComponent.suppliers.find((supplier) => supplier.id === supplierId);

      if (selectedSupplier) {
        this.selectedSupplierDetails[componentId] = {
          moq: selectedSupplier.moq,
          rate: selectedSupplier.rate,
          days: selectedSupplier.days,
          supplierId: selectedSupplier.id,
        };
      }
    }
  }

  openUpdateSupplierModal(componentId: string, supplierId: string | undefined): void {
    if (!supplierId) {
      this.toastr.error('Please select a supplier first.');
      return;
    }

    const dialogRef = this.dialog.open(UpdateSupplierComponent, {
      width: '800px', 
      height:'600px',
      data: {
        componentId: componentId,
        supplierId: supplierId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadComponentSuppliers(); 
      }
    });
  }
}
