import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginPageComponent } from './modules/auth/pages/login-page/login-page.component';
import { RegisterComponent } from './modules/auth/pages/register/register.component';
import { MatMenuModule } from '@angular/material/menu';
import { ProfileViewComponent } from './modules/profile-view/profile-view.component';
import { SelectdropComponent } from './shared/component/selectdrop/selectdrop.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatTabsModule } from '@angular/material/tabs';
import { AdminProjectsComponent } from './modules/admin/admin-projects/admin-projects.component';
import { AdminUsersComponent } from './modules/admin/admin-users/admin-users.component';
import { AdminQuotesComponent } from './modules/admin/admin-quotes/admin-quotes.component';
import { UserProjectsComponent } from './modules/users/user-projects/user-projects.component';
import { UserQuotesComponent } from './modules/users/user-quotes/user-quotes.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { QuotesViewComponent } from './modules/quotes/quotes-view/quotes-view.component';
import { SearchmodalComponent } from './modules/quotes/searchmodal/searchmodal.component';
import { InventoryComponent } from './modules/inventory/inventory.component';

import { AuditTrailComponent } from './modules/audit-trail/audit-trail.component';
import { EditItemComponent } from './modules/edit/edit-item/edit-item.component';
import { EditProjectComponent } from './modules/edit/edit-project/edit-project.component';
import { EditPurchaseComponent } from './modules/edit/edit-purchase/edit-purchase.component';
import { PurchaseDetailsComponent } from './modules/users/user-purchases/purchase-details/purchase-details.component';
import { PurchaseComponent } from './modules/users/user-purchases/purchase/purchase.component';
import { ProjectDetailsComponent } from './modules/users/user-projects/project-details/project-details.component';
import { AddComponentModalComponent } from './modules/quotes/addcomponentmodal/addcomponentmodal.component';
import { CustomTreeComponent } from './modules/custom-tree/custom-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaginationComponent } from './shared/component/pagination/pagination.component';
import { WorkPlanCreateModalComponent } from './modules/work-plan-create-modal/work-plan-create-modal.component';
import { ViewWorkPlanComponent } from './modules/quotes/view-work-plans/view-work-plans.component';
import { ViewWorkPlanDetailsComponent } from './modules/quotes/view-work-plan-details/view-work-plan-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClientInfoComponent } from './modules/client-info/client-info.component';
import { AddClientDetailsComponent } from './modules/add-client-details/add-client-details.component';
import { BuildersArchitectsComponentComponent } from './modules/builders-architects-component/builders-architects-component.component';
import { AddPlannerDetailsComponent } from './modules/add-planner-details/add-planner-details.component';
import { DownloadQuotesPdfComponent } from './modules/quotes/download-quotes-pdf/download-quotes-pdf.component';
import { RegionComponent } from './modules/region/region.component';
import { CostModalComponent } from './modules/quotes/cost-view/cost-view.component';
//

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditClientComponent } from './modules/edit-client/edit-client.component';
import { EditBuilderComponent } from './modules/edit-builder/edit-builder.component';
import { QuoteRevisionViewComponent } from './modules/quotes/quote-revision-view/quote-revision-view.component';

import { RevisionViewComponent } from './modules/quotes/revision-view/revision-view.component';
import { JobsComponent } from './modules/jobs/jobs.component';
import { JobViewComponent } from './modules/job-view/job-view.component';
import { ArchitectsComponentComponent } from './modules/builders-architects-component/architects-component/architects-component.component';
import { ContractorsComponentComponent } from './modules/builders-architects-component/contractors-component/contractors-component.component';
import { ConsultantComponentComponent } from './modules/builders-architects-component/consultant-component/consultant-component.component';
import { ComponentSupplierComponent } from './modules/component_supplier/component-supplier/component-supplier.component';
import { ComponentSupplierViewComponent } from './modules/component_supplier/component-supplier-view/component-supplier-view.component';
import { UpdateSupplierComponent } from './modules/component_supplier/update-supplier/update-supplier.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ProfileViewComponent,
    FooterComponent,
    HeaderComponent,
    ContentLayoutComponent,
    RegisterComponent,
    SelectdropComponent,
    AdminProjectsComponent,
    AdminUsersComponent,
    AdminQuotesComponent,
    UserProjectsComponent,
    UserQuotesComponent,
    QuotesViewComponent,
    SearchmodalComponent,
    InventoryComponent,
    EditItemComponent,
    PurchaseComponent,
    PurchaseDetailsComponent,
    EditProjectComponent,
    AuditTrailComponent,
    EditPurchaseComponent,
    ProjectDetailsComponent,
    AddComponentModalComponent,
    CustomTreeComponent,
    PaginationComponent,
    WorkPlanCreateModalComponent,
    ViewWorkPlanComponent,
    ClientInfoComponent,
    AddClientDetailsComponent,
    BuildersArchitectsComponentComponent,
    AddPlannerDetailsComponent,
    RegionComponent,
    DownloadQuotesPdfComponent,
    QuoteRevisionViewComponent,
    RevisionViewComponent,
    EditClientComponent,
    EditBuilderComponent,
    JobsComponent,
    JobViewComponent,
    ArchitectsComponentComponent,
    ContractorsComponentComponent,
    ConsultantComponentComponent,
    CostModalComponent,
    ViewWorkPlanDetailsComponent,
    ComponentSupplierComponent,
    ComponentSupplierViewComponent,
    UpdateSupplierComponent

  
  

 
  ],
  imports: [
    MatMenuModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgSelectModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatTreeModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
