import { INavData } from '../data/data-types';





export const navItems: INavData[] = [{
  title: true,
  name: 'Admin',
  subCat: [
    // {
    //   subname: 'Dashboard',
    //   icon: 'fa fa-solid fa-user',
    //   url: '/dashboard',
    // },
    {
      subname: 'Users',
      icon: 'fa fa-building',
      url: '/admin/admin-users',
    },
    {
      subname: 'Projects',
      icon: 'fa fa-building',
      url: '/admin/admin-projects',
    },
    {
      subname: 'Quotes',
      icon: 'fa fa-building',
      url: '/admin/admin-quotes',
    },
  ],
},
{
  title: true,
  name: 'Users / Engineers',
  subCat: [
    {
      subname: 'Profile',
      icon: 'fa fa-solid fa-user',
      url: '/profile',
    },
    {
      subname: 'Projects',
      icon: 'fa fa-briefcase',
      url: '/user/user-projects',
    },
    {
      subname: 'Quotes',
      icon: 'fa fa-folder-open',
      url: '/user/user-quotes',
    },
  
    {
      subname: 'Inventory',
      icon: 'fa fa-building',
      url: '/inventory',
    },
    {
      subname: 'Purchases',
      icon: 'fa fa-shopping-cart',
      url: '/purchase',
    },
    {
      subname: 'Audit Tracking',
      icon: 'fa fa-th-list',
      url: '/audit-tracking',
    },
    {
      subname: 'Client Details',
      icon: 'fa fa-solid fa-users',
      url: '/client-info',
    },
    {
      subname: 'Archi/Construct',
      icon: 'fa fa-solid fa-building',
      url: '/planners',
    },
    {
      subname: 'Jobs',
      icon: 'fa fa-th-list',
      url: '/job',
    },
  ],
},

];
