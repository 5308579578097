import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface ComponentData {
  id: string;
  c2: string;
  c3: string;
  c1: string;
  finish: string;
  colour: string;
  qty: number;
}

export interface Item {
  id: string;
  c2: string;
  c3: string;
  c1: string;
  finish: string;
  colour: string;
  qty: number;
  type: string;
  isQtyChanged: boolean;
}

@Component({
  selector: 'app-add-component-modal',
  templateUrl: './addcomponentmodal.component.html',
  styleUrls: ['./addcomponentmodal.component.scss']
})
export class AddComponentModalComponent implements OnInit {
  itemId!: string;
  addComponentForm: FormGroup;
  allComponents: ComponentData[] = [];
  filteredComponents: ComponentData[] = [];
  segmentOptions: string[] = [];
  filteredComponentTypeOptions: string[] = [];
  newItems: Item[] = [];
  filteredOptions: string[] =[];
  filteredSegmentOptions: string[] =[];
  // isQtyChanged!: boolean;

  constructor(
    private fb: FormBuilder,
    private apiHelper: ApiHelper, 
    private toastr: ToastrService, 
    public dialogRef: MatDialogRef<AddComponentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any 
  ) {
    this.addComponentForm = this.fb.group({
      selectedSegment: [null],         // selectedSegment will be reset when changed
      selectedComponentType: [null],   // selectedComponentType will be reset
    });

    // Watch for changes in selectedSegment and update the component types
    this.addComponentForm.get('selectedSegment')?.valueChanges.subscribe((segment) => {
      this.filteredOptions = [...this.segmentOptions]
      this.updateComponentTypeOptions(segment);   // Update component types based on selected segment
      this.addComponentForm.get('selectedComponentType')?.setValue(null);
      // Reset selected component type
    });

    // Watch for changes in selectedComponentType and filter components based on type
    this.addComponentForm.get('selectedComponentType')?.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((type) => {
      this.filterComponents(type);  // Filter components when a component type is selected
    });
  }

  ngOnInit() {
    this.loadAllComponents();
  }

  loadAllComponents() {
    this.apiHelper.post({}, ApiEndPoints.getAllItems).subscribe(
      (response) => {
        if (response.data) {
          this.allComponents = response.data;
          this.extractSegmentOptions();  // Extract segments from the component data
        } else {
          this.toastr.error('Failed to load components.');
        }
      },
      () => {
        this.toastr.error('Error occurred while fetching components.');
      }
    );
  }

  extractSegmentOptions() {
    const segmentSet = new Set<string>();
    this.allComponents.forEach((component) => segmentSet.add(component.c1));  // Add unique segments (c1)
    this.segmentOptions = Array.from(segmentSet);
    this.filteredSegmentOptions = [...this.segmentOptions]  // Convert the Set to an array
  }

  // Clear previously filtered components and update component type options
  updateComponentTypeOptions(selectedSegment: string) {
    this.filteredComponents = [];  // Reset filtered components
    this.filteredComponentTypeOptions = [];  // Reset available component types

    if (selectedSegment) {
      const typeSet = new Set<string>();
      this.allComponents
        .filter((component) => component.c1 === selectedSegment)  // Filter by selected segment (c1)
        .forEach((component) => typeSet.add(component.c2));  // Add component types (c2) to a Set

      this.filteredComponentTypeOptions = Array.from(typeSet); 
      this.filteredOptions = [...this.filteredComponentTypeOptions] // Convert Set to array for dropdown options
    }
  }

  // Filter components based on the selected component type
  filterComponents(selectedType: string) {
    if (selectedType) {
      this.filteredComponents = this.allComponents.filter(
        (component) => component.c2 === selectedType  // Filter components by selected type (c2)
      );
      this.filteredOptions = [...this.filteredComponentTypeOptions] 
    } else {
      this.filteredComponents = [];  // Reset if no type is selected
    }
  }

  addComponentFromList(component: ComponentData) {
    const item: Item = {
      id: component.id,
      c1: component.c1,
      c2: component.c2,
      c3: component.c3,
      finish: component.finish,
      colour: component.colour,
      qty: component.qty,  // Assuming qty is a part of ComponentData
      type: component.c2 ,
      isQtyChanged :  false // Use c2 (or another property) for the type
    };
  
    this.newItems.unshift(item);  // Add the selected component to the newItems array
  }

  removeComponent(componentToRemove: Item) {
    this.newItems = this.newItems.filter(
      (item) => item.id !== componentToRemove.id  // Remove the selected component from the list
    );
  }

  updateComponentQuantity(component: ComponentData, newQuantity: number) {
    // Find the index of the item to update in the newItems array.
    const itemIndex = this.newItems.findIndex(item => item.id === component.id);
    if (itemIndex !== -1) {
      if (newQuantity && newQuantity > 0) {
        this.newItems[itemIndex].qty = newQuantity; 
        this.newItems[itemIndex].isQtyChanged = false;  // Update the quantity
        this.toastr.success('Quantity updated successfully.');
      } else {
        this.toastr.error('Please enter a valid quantity.');
      }
    } else {
      this.toastr.error('Component not found.');
    }
  }
  onInputChange(event: any, item: any) {
    const originalQty = event.target.value;

    if (originalQty !== item.qty) {
      item.isQtyChanged = true;
    } else {
      item.isQtyChanged = false;
    }
  }
  closeDialog() {
 
  
    this.dialogRef.close({ selectedComponents: this.newItems });
  }

  onSearchChange(event:any) {
    let searchTerm = event.term
    
    if (!searchTerm) {
      this.filteredOptions = [...this.filteredComponentTypeOptions];
      return;
    }

    searchTerm = searchTerm.toLowerCase(); // Convert to lowercase for case-insensitive matching
 
    // Filter and sort the options dynamically based on the search term
    this.filteredOptions = [...this.filteredComponentTypeOptions];
    this.filteredOptions= this.filteredOptions
      .sort((a, b) => this.customSortFn(searchTerm, a, b))  // Sorting logic
      .filter(item => this.customFilterFn(searchTerm, item));  // Filtering logic
  }

  customSortFn(searchTerm: string, a: string, b: string): number {
    const aStartsWith = a.toLowerCase().startsWith(searchTerm);
    const bStartsWith = b.toLowerCase().startsWith(searchTerm);

    if (aStartsWith && !bStartsWith) {
      return -1;  // 'a' comes first
    }
    if (!aStartsWith && bStartsWith) {
      return 1;  // 'b' comes first
    }
    return a.localeCompare(b);  // If neither starts with the term, sort alphabetically
  }

  customFilterFn(searchTerm: string, item: string): boolean {
    return item.toLowerCase().includes(searchTerm);  // Filter based on inclusion
  }

  onSearchChangeSegment(event:any) {
    let searchTerm = event.term
    // this.filteredSegmentOptions = [...this.segmentOptions];
    if (!searchTerm) {
      this.filteredSegmentOptions = [...this.segmentOptions];
      return;
    }
    searchTerm = searchTerm.toLowerCase(); // Convert to lowercase for case-insensitive matching
 
    // Filter and sort the options dynamically based on the search term
    this.filteredSegmentOptions = [...this.segmentOptions];
    this.filteredSegmentOptions= this.filteredSegmentOptions
      .sort((a, b) => this.customSortFn(searchTerm, a, b))  // Sorting logic
      .filter(item => this.customFilterFn(searchTerm, item));  // Filtering logic
  }
  onClearSelection(){
    this.filteredSegmentOptions = [...this.segmentOptions];
}
onClear(): void {
  this.filteredOptions = [...this.filteredComponentTypeOptions];
}
}







